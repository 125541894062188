.add-sites-icon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  background-color: rgba(0, 0, 0, 0);
  border: 4px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.add-sites-popup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 384px;
  padding: 5px 5px 20px 5px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  background: rgba(255, 255, 255, 1);
  text-align: center;
}

.add-sites-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.add-sites-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.add-sites-close-btn {
  background: none;
  border: none;
  float: right;
}

.sites-input-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.required-sites {
  color: red;
  right: 10px;
}

.add-sites-title {
  font-family: Inria Sans, serif;
  font-size: 24px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.add-sites-body {
  margin: 0 30px;
}

.add-sites-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.asi-input-required {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.asi-title {
  text-align: left;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

.add-site-text {
  border: 1px solid rgb(225, 225, 225);
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  outline: none;
}

.add-site-text::placeholder {
  color: rgb(176, 176, 176);
  font-size: 15px;
}

.add-site-text:focus::placeholder {
  color: transparent;
}

.error-msg-site-link {
  color: red;
  font-size: 14px;
  float: left;
}

.site-add {
  width: 96px;
  height: 35px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(58, 58, 58, 1);
  font-family: Inria Sans, serif;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #ffffff;
}

.site-add:hover {
  background: rgb(0, 0, 0);
}

.site-toggle-alert {
  position: fixed;
  width: 380px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  padding: 20px;
  gap: 20px;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.site-alert-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
}

.site-alert-text {
  font-size: 14px;
  color: #676767;
  font-weight: lighter;
}

.site-upgrade-alert {
  font-weight: bold;
  font-size: 14px;
}

.site-upgrade-button {
  cursor: pointer;
  padding: 5px 20px;
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  color: white;
  border-radius: 10px;
}

.site-upgrade-button:hover {
  background: rgb(27, 27, 27);
}

@media only screen and (max-width: 426px) {
  .add-sites-icon {
    width: 50px;
    height: 50px;
    background-size: 70%;
  }

  .add-sites-popup {
    gap: 20px;
    width: 300px;
  }

  .add-sites-header {
    height: 40px;
  }

  .add-sites-title {
    font-size: 20px;
  }

  .add-sites-body {
    margin: 0 15px;
  }

  .asi-title {
    font-size: 14px;
  }

  .add-site-text::placeholder {
    font-size: 12px;
  }

  .site-toggle-alert {
    width: 85%;
  }

  .site-alert-text,
  .site-upgrade-alert {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 769px) and (min-width: 427px) {
  .add-sites-icon {
    width: 50px;
    height: 50px;
    background-size: 70%;
  }
}

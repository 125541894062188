.insights-modal {
  display: flex;
  flex-direction: column;
  width: 384px;
  max-height: calc(100vh - 140px);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 0 5px 40px 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.insights-menu-bar {
  display: none;
  position: sticky;
}

.insights-to-home {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-photo {
  width: 37px;
  height: 37px;
  object-fit: cover;
  color: rgb(128, 128, 128);
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
}

.insights-to-home-icon {
  width: 37px;
  height: 37px;
  object-fit: cover;
  color: rgb(128, 128, 128);
  cursor: pointer;
  overflow: hidden;
}

.insights-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.insights-title {
  font-size: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  height: 50px;
}

.insights-close {
  position: absolute;
  right: 12px;
  top: 5px;
}

.insights-close-btn {
  background: none;
  border: none;
  float: right;
}

.insights-container {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 20px 10px 20px;
}

.insights-container::-webkit-scrollbar {
  width: 0;
}

.total-views-body,
.unique-views-body,
.clicks-insights-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
}

.total-views-body {
  padding-top: 10px;
}

.tv-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.total-views-title {
  font-size: 20px;
}

.total-views-desc {
  font-size: 12px;
  color: #646464;
}

.total-views-count {
  font-weight: bold;
  font-size: 30px;
}

.tv-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.tv-filter-sec {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
}

.filter-by {
  font-size: 12px;
}

.filter-by-tabs {
  font-size: 12px;
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8e8;
  border-radius: 20px;
  cursor: pointer;
}

.filter-by-tabs.active {
  border: 2px solid #9f9f9f;
}

.tv-chart {
  max-width: 354px;
  overflow: hidden;
  overflow-x: auto;
}

.tv-chart::-webkit-scrollbar {
  width: 0;
}

.tv-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  max-width: 354px;
  overflow: hidden;
  overflow-x: auto;
  padding-left: 20px;
}

.tv-bottom::-webkit-scrollbar {
  width: 0;
  display: none;
}

.tv-progress-sec {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 150px;
  align-items: center;
  justify-content: center;
}

.tv-progress-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.tv-progress-count {
  font-size: 18px;
  font-weight: bolder;
}

.tv-progress-desc {
  font-size: 14px;
  font-weight: bold;
  width: 110px;
}

.insights-upgrade-msg {
  background: linear-gradient(#fff86d, #ff55e3);
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.new-insight-title {
  font-size: 16px;
  color: #797979;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-insights-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.sample-data-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #797979;
  font-weight: bold;
}

.sample-data-desc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 12px;
  color: #797979;
}

.unlock-insights {
  font-weight: bolder;
  font-size: 16px;
}

.unlock-insights-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.unlock-insights-desc {
  font-size: 12px;
  margin-bottom: 10px;
}

.unlock-insights-sections {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 10px;
  gap: 20px;
  text-align: left;
}

.unlock-insights-icon {
  font-size: 12px;
  color: #0f00b0;
}

.unlock-insights-sent {
  font-size: 12px;
  font-weight: bold;
  flex: 1;
  width: 100%;
}

.insights-upgrade-btn {
  width: 114px;
  height: 40px;
  color: white;
  background-color: #2b2b2b;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uv-top {
  background-color: #44446f;
  border-radius: 10px;
  height: 146px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.unique-views-sec {
  height: 80%;
  width: 50%;
  border-right: 1px solid #888888;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 5px;
}

.uvs-title {
  font-size: 20px;
  color: white;
}

.uvs-desc {
  font-size: 10px;
  color: #b3b3b3;
}

.uvs-count {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.repeat-views-sec {
  height: 80%;
  width: 50%;
  padding: 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rvs-title {
  font-size: 14px;
  color: white;
}

.rvs-count {
  font-weight: bold;
  color: black;
  font-size: 18px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #c0c3e4;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3), 4px 2px 4px rgba(0, 0, 0, 0.05);
}

.uv-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.profile-visitors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pv-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: bold;
}

.pv-desc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  color: #636363;
}

.pv-details {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 0 10px;
  gap: 10px;
}

.pv-profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #cdcdcd;
}

.pv-details-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.pv-name {
  width: 100%;
  display: flex;
  text-align: left;
  font-size: 10px;
}

.pv-designation {
  width: 100%;
  display: flex;
  text-align: left;
  font-size: 8px;
}

.pv-timestamp {
  font-size: 8px;
  color: #949494;
}

.profile-visitor-source {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.visitor-sources-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 20px;
  margin-top: 20px;
}

.platform-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.platform-name {
  font-size: 12px;
  color: #9a9a9a;
}

.ci-top-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.clicks-image {
  width: 90px;
}

.clicks-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 10px;
}

.clicks {
  font-size: 28px;
  font-weight: bold;
  width: 100%;
}

.clicks-count {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 426px) {
  .insights-modal {
    gap: 10px;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
    padding: 10px 10px 20px 10px;
  }

  .insights-menu-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(59, 59, 59);
    padding: 5px 10px;
    border-radius: 25px;
  }

  .insights-header {
    display: none;
  }

  .imb-title {
    font-size: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #d5d5d5;
  }

  .insights-container {
    height: 100vh;
    max-height: 100vh;
    z-index: 10;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 768px) and (min-width: 427px) {
  .insights-modal {
    max-width: 370px;
    gap: 10px;
    padding-bottom: 50px;
  }

  .imb-title {
    font-size: 20px;
    height: 40px;
  }

  .insights-container {
    gap: 20px;
  }
}

.insights-container ::-webkit-scrollbar {
  width: 5px;
}

.insights-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.insights-container::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.insights-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.stats-section {
  display: flex;
  justify-content: space-around;
  gap: 50px;
}

.stat-item {
  text-align: center;
}

.progress-bar-container {
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
 width: 100%;
 gap: 20px;
}

.stat-item h4 {
  margin-top: 10px;
}

.stat-item p {
  margin: 5px 0;
}

.ctr-indicator {
  margin-top: 20px;
}

.ctr-indicator .circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #3f51b5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.ctr-indicator .inner-circle {
  font-size: 20px;
}

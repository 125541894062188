.settings-popup {
  width: 320px;
  max-height: 600px;
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translate(-50%, 0);
  gap: 20px;
  z-index: 999;
  padding: 5px 5px 10px 5px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  text-align: center;
}

.sp-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.sp-close {
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 0;
}

.sp-title {
  font-size: 24px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.sp-body {
  width: 100%;
  padding: 0 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  max-height: 350px;
}

.sp-body::-webkit-scrollbar {
  width: 5px;
}

.sp-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.sp-body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.sp-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.setting-type {
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.setting-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.setting-title {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
}

.setting-type-icon {
  font-size: 20px;
  cursor: pointer;
}

.settings-type-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  width: 100%;
  padding: 20px;
  gap: 10px;
  border-radius: 5px;
}

.setting-sub-title {
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.choose-color-sec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 9px;
  width: 93%;
  cursor: pointer;
  position: relative;
  padding: 10px;
  background-color: white;
}

.choose-color {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.choose-color-selected {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.choose-color-lock {
  color: white;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.137);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 14px;
  border: none;
  outline: none;
}

.unlock-colors-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.unlock-colors-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
  height: 30px;
  padding-right: 10px;
  padding-top: 10px;
}

.unlock-colors-msg {
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.unlock-colors-btn {
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 5px 20px;
  width: fit-content;
  color: #ffffff;
  background-color: #3b3b3b;
  cursor: pointer;
  border-radius: 25px;
}

.unlock-colors-btn:hover {
  background-color: #242424;
}

.choose-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: white;
  width: 100%;
  padding: 14px;
}

.choose-template-desc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
}

.choose-template-radio {
  width: 100%;
  display: flex;
  padding-left: 20px;
  gap: 20px;
}

.template-radio {
  display: flex;
  align-items: center;
  gap: 5px;
}

.templates-radio-btn {
  font-size: 12px;
}

.general-settings-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  background-color: white;
  padding: 10px;
}

.s-name-sec,
.email-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.email-change-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.s-input,
.s-input-email {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
}

.s-input-email {
  color: rgb(164, 164, 164);
  font-size: 12px;
}

.s-name-label,
.s-name-instruction {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #494949;
}

.s-name-instruction {
  font-size: 12px;
}

.gs-error-msg {
  font-size: 10px;
  color: red;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
}

.email-label,
.email-change {
  width: 100%;
  font-size: 12px;
  color: #494949;
}

.email-change {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-weight: bold;
  font-size: 10px;
  text-decoration: none;
}

.delete-acc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 12px;
  color: red;
  padding-left: 10px;
}

.s-input::placeholder {
  color: rgb(198, 198, 198);
  font-weight: 300;
  font-size: 14px;
}

.s-email {
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #494949;
  margin: 0;
  padding: 0;
}

.change-s-email {
  border: none;
  outline: none;
  color: rgb(0, 0, 115);
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.insights-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.insights-title {
  font-size: 20px;
  color: #494949;
}

.insights-count {
  font-size: 28px;
}

.insights-desc {
  font-size: 12px;
  color: #888888;
}

.insights-note {
  font-size: 14px;
  color: #888888;
}

.insights-upgrade {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.insights-upgrade div {
  color: #ffffff;
  background-color: #3b3b3b;
  outline: none;
  padding: 5px 20px;
  border-radius: 25px;
  font-weight: 500;
  transition: 0.5s;
  cursor: pointer;
}

.insights-upgrade div:hover {
  background: #242424;
}

.privacy-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.privacy-tab {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.privacy-icon {
  font-size: 14px;
}

.privacy-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  flex: 1;
  width: 100%;
}

.privacy-type-label {
  width: 100%;
  display: flex;
  font-size: 14px;
}

.privacy-type-desc {
  font-size: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: lighter;
  color: #888888;
}

.plan-canvas-free,
.plan-canvas-silver,
.plan-canvas-gold {
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.pc-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.278);
  padding-bottom: 10px;
}

.plan-name {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.plan-price {
  width: 100%;
  font-size: 28px;
  font-weight: bolder;
  text-align: center;
}

.plan-sub-desc {
  font-size: 16px;
  color: rgb(29, 31, 31);
  font-weight: bold;
}

.pc-middle-btn {
  width: 100%;
  padding: 5px;
  border-radius: 20px;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.156);
}

.pc-middle-btn:hover {
  background-color: rgba(0, 0, 0, 0.111);
}

.pc-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.plan-features {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.plan-features-icon {
  font-size: 16px;
  color: rgb(36, 39, 36);
}

.plan-features-desc {
  flex: 1;
  padding: 0;
  margin: 0;
  display: flex;
  text-align: left;
}

.pc-footer {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: rgb(37, 40, 41);
}

.plan-canvas-free {
  background: linear-gradient(rgba(13, 223, 255, 0.2), rgba(49, 192, 39, 0.2));
}

.plan-canvas-silver {
  background: linear-gradient(rgba(0, 0, 0, 0.06), rgba(19, 0, 106, 0.13));
}

.plan-canvas-gold {
  background: linear-gradient(rgba(255, 215, 17, 0.2), rgba(106, 0, 0, 0.2));
}

.click-plan-compare {
  cursor: pointer;
  border-bottom: 1px solid rgb(0, 0, 61);
  color: rgb(0, 0, 61);
}

.accordion-button {
  background-color: transparent !important;
  margin: 0;
  padding: 0 10px;
  height: 40px !important;
  color: #888888;
  border-radius: 5px 5px 0 0;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: rgb(244, 244, 244) !important;
}

.accordion-body {
  margin: 0;
  padding: 20px 0;
  background-color: rgb(244, 244, 244) !important;
  border-radius: 0 0 5px 5px;
}

.sp-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-save {
  width: 130px;
  height: 40px;
  color: #ffffff;
  background: rgba(58, 58, 58, 1);
  border-radius: 10px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-save:hover {
  background-color: #000000;
}

.spinner-save {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.radio-btn {
  width: 16px;
  height: 16px;
  accent-color: black;
}

.error-msg-name {
  color: red;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 1px;
}

.delete-profile-modal,
.profile-deleted {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  box-shadow: 10px 10px 382px 200px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 10px 10px 382px 200px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 10px 10px 382px 200px rgba(0, 0, 0, 0.19);
  background-color: white;
  padding: 20px;
  border-radius: 25px;
  z-index: 999;
}

.name-count-error {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.32);
  background-color: white;
  border-radius: 10px;
  z-index: 999;
}

.profile-deleted {
  width: 280px;
}

.dp-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
  margin-bottom: 20px;
}

.dp-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 20px;
}

.dp-close-btn {
  position: absolute;
  display: flex;
  cursor: pointer;
  top: 0;
  right: 0;
}

.dp-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.dp-body-desc {
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dp-body-desc-note {
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: bold;
}

.dp-body-desc-note span {
  width: 100%;
}

.dp-body-notes {
  padding-left: 30px;
}

.retype-username {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.retype-description {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
}

.retype-textbox {
  width: 100%;
  padding: 5px 10px;
  font-size: 12px;
  border: 1px solid rgb(186, 186, 186);
  border-radius: 5px;
  outline: none;
}

.delete-profile-error {
  color: red;
  font-size: 12px;
  width: 100%;
  display: flex;
  text-align: left;
}

.dp-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.dp-cancel {
  width: 100px;
  cursor: pointer;
  border-radius: 25px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
}

.dp-confirm {
  width: 100px;
  cursor: pointer;
  border-radius: 25px;
  color: white;
  background-color: #494949;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
}

.pd-desc {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;
}

.pd-notes {
  font-size: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 426px) {
  .settings-popup {
    width: 280px;
    max-height: 500px;
    top: 55px;
    left: 50%;
    transform: translate(-50%, 0);
    gap: 10px;
  }

  .sp-body {
    padding: 0 14px;
  }

  .choose-color-sec {
    gap: 10px;
    justify-content: center;
  }

  .unlock-colors-modal {
    width: 250px;
  }

  .dp-close-btn {
    top: 8px;
    right: 0;
    display: none;
  }

  .dp-body-desc {
    font-size: 12px;
    text-align: justify;
  }

  .dp-body-desc-note span,
  .dp-body-notes {
    font-size: 12px;
  }

  .delete-profile-modal {
    width: 100vw;
    height: 100vh;
    padding: 0 40px 20px 40px;
    border-radius: 0;
  }


  .dp-footer {
    justify-content: flex-end;
  }

  .dp-cancel,
  .dp-confirm {
    font-size: 14px;
    width: 80px;
  }

  .retype-description{
    display: flex;
    text-align: left;
  }
}

@media screen and (max-width: 768px) and (min-width: 427px) {
  .settings-popup {
    width: 320px;
    max-height: 500px;
    top: 55px;
    left: 50%;
    transform: translate(-50%, 0);
    gap: 10px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 769px) {
  .settings-popup {
    max-height: 500px;
    top: 55px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.demographic {
  gap: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
}

.demo-icon {
  width: 16px;
  height: 16px;
}

.demo-title {
  font-size: 10px;
  color: rgba(255, 255, 255, 90%);
}

.designation-popup {
  overflow: hidden;
  width: 360px;
  height: auto;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  background: rgba(255, 255, 255);
  text-align: center;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
}

.designation-box {
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  margin-bottom: 20px;
  position: relative;
}

.designation-title {
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  height: 100%;
}

.designation-body {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 10px;
}

.type-designation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.type-designation input {
  color: rgba(58, 58, 58);
  outline: none;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 10px;
}

.input::placeholder {
  color: rgba(58, 58, 58, 1);
}

.des-pb-error-message {
  color: red;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  padding-left: 3px;
}

.designation-close-btn {
  background: none;
  border: none;
  top: 5px;
  right: 15px;
  position: absolute;
}

.designation-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  padding-bottom: 20px;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.designation-add {
  width: 96px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(58, 58, 58);
  font-size: 20px;
  border: none;
  color: #ffffff;
}

.designation-add:hover {
  background: rgb(0, 0, 0);
}

.designation-update-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.designation-update {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 25px;
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.designation-update:hover {
  background: rgb(27, 27, 27);
}

.designation-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 18px;
  border: 2px solid rgb(195, 195, 195);
  background-color: transparent;
  text-align: center;
}

.designation-delete:hover {
  background: rgb(220, 220, 220);
}

.success-message {
  background-color: rgb(162, 160, 160);
  color: white;
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

@media screen and (max-width: 576px) {
  .designation-popup {
    width: 300px;
    text-align: center;
  }

  .designation-box {
    height: 60px;
  }

  .designation-title {
    font-size: 20px;
    text-align: center;
  }

  .designation-update,
  .designation-delete {
    font-size: 16px;
  }

  .type-designation {
    font-size: 14px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 577px) {
  .designation-popup {
    width: 300px;
    text-align: center;
  }

  .designation-update,
  .designation-delete {
    font-size: 16px;
  }

  .type-designation {
    font-size: 14px;
  }
}

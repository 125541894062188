.bio-description {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.edit-icon {
  width: 16px;
  height: 16px;
  color: rgba(255, 255, 255, 80%);
  cursor: pointer;
  margin-right: 10px;
}

.bio-desc {
  color: rgba(255, 255, 255, 80%);
  font-weight: 300;
  font-size: 15px;
}

.bio-popup {
  overflow: hidden;
  width: 384px;
  height: auto;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
}

.bp-box {
  width: 384px;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.bp-title {
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  height: 100%;
}

.bp-close-btn {
  background: none;
  border: none;
  top: 3px;
  right: 10px;
  position: absolute;
}

.bio-popup-body {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bio-popup-body-canvas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bio-body {
  width: 100%;
  outline: none;
  border: 1px solid rgb(186, 186, 186);
  border-bottom: none;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
  padding: 10px;
  margin: 0;
}

.bio-body::placeholder {
  color: #cacaca;
  padding-left: 10px;
  font-size: 14px;
}

.char-bio {
  outline: none;
  width: 100%;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(186, 186, 186);
  border-top: none;
  justify-content: flex-end;
  background-color: white;
  color: rgb(118, 118, 118);
  font-size: 16px;
  padding-right: 10px;
}

.bio-popup-footer {
  text-align: center;
  padding-bottom: 20px;
}

.bp-save {
  width: 96px;
  height: 35px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(58, 58, 58);
  font-size: 20px;
  border: none;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
}

.bp-save:hover {
  background: rgb(0, 0, 0);
}

.bio-pb-error-message {
  color: red;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 10px;
}

.bp-cancel {
  width: 96px;
  height: 35px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(58, 58, 58, 1);
  font-size: 20px;
  border: none;
  text-align: center;
  color: #ffffff;
}

.bp-cancel:hover {
  background: rgb(36, 35, 35);
}

.bio-update-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.bio-update {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 5px 10px;
  border-radius: 25px;
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.bio-update:hover {
  background: rgb(27, 27, 27);
}

.bio-delete {
  width: 100px;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 18px;
  border: 2px solid rgb(195, 195, 195);
  background-color: transparent;
  text-align: center;
}

.bio-delete:hover {
  background: rgb(220, 220, 220);
}

@media screen and (max-width: 576px) {
  .edit-icon {
    width: 12px;
    height: 12px;
  }

  .bio-desc {
    font-size: 14px;
  }

  .bio-popup {
    width: 280px;
    text-align: center;
  }

  .bp-box {
    width: 280px;
    height: 60px;
  }

  .bio-body {
    width: 250px;
  }

  .bp-title {
    font-size: 22px;
    text-align: center;
  }
}

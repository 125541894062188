.share-profile-popup {
  width: 350px;
  max-height: calc(100vh - 100px);
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
}

.spp-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(224, 223, 223);
  padding-bottom: 10px;
}

.spp-title {
  font-size: 24px;
}
.spp-close {
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
}

.spp-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  overflow: auto;
}

.spp-body-icon {
  position: absolute;
  top: 25px;
  left: 60px;
  cursor: pointer;
}

.spp-copy-url1 {
  border: 1px solid rgb(218, 218, 218);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
}

.spp-url1,
.spp-url2 {
  max-width: 83%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.spp-url1::-webkit-scrollbar,
.spp-url2::-webkit-scrollbar {
  height: 0;
}

.spp-url-copyicon1,
.spp-url-copyicon2 {
  font-size: 20px;
  color: rgb(191, 191, 191);
  cursor: pointer;
}

.copied-text {
  font-size: 16px;
}

.spp-body-qr {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  position: relative;
}

.spp-body-qr-code1,
.spp-body-qr-code2 {
  width: 200px;
  height: 200px;
  padding: 10px;
  border: 2px solid rgb(233, 233, 233);
  border-radius: 10px;
  background-color: white;
}

.qr-code-img {
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-qr {
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.441);
  position: absolute;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.download-qr-icon1,
.download-qr-icon2 {
  background-color: rgb(239, 239, 239);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  object-fit: cover;
  font-size: 38px;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.spp-body-qr:hover .download-qr {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s, visibility 0.3s;
}

.spp-share-msg {
  width: 100%;
}

.spp-share-own,
.spp-share-other {
  width: 100%;
  padding: 5px;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 5px;
}

.ssm-edit {
  border: none;
  width: 100%;
  outline: none;
}

.spp-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spp-share-btn {
  border: none;
  outline: none;
  color: white;
  background-color: rgb(52, 52, 52);
  padding: 5px 20px;
  transition: 0.4s;
  border-radius: 10px;
  cursor: pointer;
}

.spp-share-btn:hover {
  background-color: black;
}

.char-social {
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: rgb(118, 118, 118);
  font-size: 14px;
  padding-right: 10px;
}

@media only screen and (max-width: 768px) {
  .share-profile-popup {
    width: 280px;
    max-height: 450px;
  }

  .spp-header {
    padding-bottom: 5px;
  }

  .spp-title {
    font-size: 18px;
  }

  .spp-copy-url {
    height: 35px;
  }

  .spp-url-copyicon {
    font-size: 18px;
  }

  .copied-text {
    font-size: 12px;
  }

  .spp-body-qr-code1,
  .spp-body-qr-code2 {
    width: 130px;
    height: 130px;
  }

  .download-qr {
    width: 130px;
    height: 130px;
  }

  .spp-share-own,
  .spp-share-other {
    padding: 10px;
  }

  .ssm-edit {
    font-size: 12px;
    width: 100%;
    letter-spacing: -0.5px;
  }
}

@media only screen and (max-width: 1025px) {
  .share-profile-popup {
    width: 300px;
    max-height: 480px;
  }

  .spp-header {
    padding-bottom: 5px;
  }

  .spp-title {
    font-size: 20px;
  }

  .spp-copy-url {
    height: 35px;
  }

  .spp-url-copyicon {
    font-size: 18px;
  }

  .copied-text {
    font-size: 14px;
  }

  .spp-body-qr-code1,
  .spp-body-qr-code2 {
    width: 130px;
    height: 130px;
  }

  .download-qr {
    width: 130px;
    height: 130px;
  }

  .spp-share-own,
  .spp-share-other {
    padding: 10px;
  }

  .ssm-edit {
    font-size: 14px;
    width: 100%;
    letter-spacing: -0.5px;
  }
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notification-icon,
.notification-icon-default {
  width: 38px;
  height: 38px;
  cursor: pointer;
  color: white;
}

.notification-icon {
  transform: rotate(30deg);
}

.notification-count-icon {
  position: absolute;
  background-color: red;
  border: 1px solid white;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  right: 0;
  top: 10px;
}

.notification-popup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 384px;
  max-height: 550px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 0 5px 40px 5px;
}

.notification-menu-bar {
  display: none;
}

.n-to-home {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.n-to-home-icon {
  width: 37px;
  height: 37px;
  object-fit: cover;
  color: rgb(128, 128, 128);
  cursor: pointer;
  overflow: hidden;
}

.home-photo {
  width: 37px;
  height: 37px;
  object-fit: cover;
  color: rgb(128, 128, 128);
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.notification-close {
  position: absolute;
  right: 12px;
  top: 5px;
}

.notification-close-btn {
  background: none;
  border: none;
  float: right;
}

.notification-title {
  font-size: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  height: 50px;
}

.np-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.notification-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
}

.connection-requests,
.pl-requests {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-bottom: none;
  padding: 10px 0;
  border-radius: 20px 20px 0 0;
  width: 100px;
  cursor: pointer;
  font-weight: bold;
}

.connection-requests.active {
  border-bottom: 2px solid rgb(196, 196, 196);
}

.pl-requests.active {
  border-bottom: 2px solid rgb(196, 196, 196);
}

.notification-body {
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
  height: fit-content;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  z-index: 10;
  max-height: 350px;
}

.notification-body::-webkit-scrollbar {
  width: 5px;
}

.notification-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.notification-body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.notification-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.empty-notification {
  font-size: 14px;
}

.notification-no {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding-bottom: 20px;
}

.user-logo {
  font-size: 70px;
  text-align: center;
  color: rgb(202, 202, 202);
}

.notification-user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
}

.notification-user-name {
  color: #585858;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}

.notify-name {
  text-align: left;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  flex: 1;
  cursor: pointer;
}

.notify-time-sign {
  font-size: 12px;
  width: auto;
}

.notification-user-occupation {
  color: #616161;
  font-size: 12px;
  width: 100%;
  display: flex;
  text-align: left;
}

.added-user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  text-align: left;
}

.notification-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 5px;
}

.notification-confirm,
.notification-delete {
  height: 35px;
  width: 100%;
  border-radius: 20px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(160, 160, 160);
  background-color: transparent;
  font-size: 14px;
  outline: none;
  transition: 0.3s;
  font-weight: bold;
}

.notification-confirm {
  background-color: rgb(0, 183, 255);
  color: white;
  border: none;
}

.notification-delete {
  color: #585858;
}

.notification-confirm:hover {
  background-color: rgb(0, 164, 228);
}

.notification-delete:hover {
  background-color: rgb(207, 207, 207);
}

.acp-body-radio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  gap: 20px;
  font-size: 14px;
  width: 100%;
}

.loader-class {
  justify-content: center;
  align-items:flex-start;
  display: flex;
  height: 100%;
  width: 100%;
}

.loader {
  border: 2px solid #6f6d6d;
  border-top: 2px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 426px) {
  .notification-icon,
  .notification-icon-default {
    width: 35px;
    height: 35px;
  }

  .notification-count-icon {
    width: 12px;
    height: 12px;
    font-size: 9px;
    right: 0;
    top: 10px;
  }

  .notification-popup {
    gap: 10px;
    width: 100vw;
    max-height: 100vh;
    border-radius: 0;
    padding: 10px 10px 20px 10px;
  }

  .notification-menu-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(59, 59, 59);
    padding: 5px 10px;
    border-radius: 25px;
  }

  .nmb-title {
    font-size: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #d5d5d5;
  }

  .settings-popup {
    top: 60px;
    right: 10px;
    box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.1);
  }

  .notification-header {
    display: none;
  }

  .np-container {
    height: 100vh;
    z-index: 10;
    gap: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .notification-body {
    max-height: calc(100vh - 150px);
    flex: 1;
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) and (min-width: 427px) {
  .notification-icon {
    width: 35px;
    height: 35px;
  }

  .notification-count-icon {
    width: 12px;
    height: 12px;
    font-size: 7px;
    right: 0;
    top: 10px;
  }

  .notification-popup {
    max-width: 330px;
    max-height: 480px;
    gap: 10px;
    padding-bottom: 20px;
  }

  .notification-title {
    font-size: 20px;
    height: 40px;
  }

  .np-container {
    gap: 10px;
  }

  .notification-body {
    max-height: calc(100vh - 350px);
    flex: 1;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1025px) and (min-width: 769px) {
  .notification-icon {
    width: 45px;
    height: 45px;
  }

  .notification-count-icon {
    width: 15px;
    height: 15px;
    font-size: 9px;
    right: 2px;
    top: 13px;
  }

  .notification-popup {
    max-width: 330px;
    max-height: 600px;
    gap: 10px;
    padding-bottom: 20px;
  }

  .notification-title {
    font-size: 20px;
    height: 40px;
  }

  .np-container {
    gap: 10px;
  }

  .notification-body {
    max-height: calc(100vh - 420px);
    flex: 1;
    overflow-y: auto;
  }
}

.login-wrapper {
  background-color: #0fdef8;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 428px;
  height: 100vh;
  padding: 11px 28px;
  gap: 10px;
}

.top-section {
  width: 100%;
  flex: 1;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.logo img {
  width: 275px;
  height: 275px;
}

.mid-section {
  color: #ffffff;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.mid-section p {
  height: fit-content;
  margin: 0;
  padding: 0;
  width: 100%;
}

.p1 {
  font-family: "Paprika", sans-serif;
  font-size: 32px;
}

.p2 {
  font-family: "Salsa", sans-serif;
  font-size: 24px;
}

.p3 {
  font-family: "Paprika", sans-serif;
  font-size: 24px;
}

.ml-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  width: 80%;
  height: 50px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: #615858;
}

.btn-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  flex: none;
  border-radius: 50%;
  border: none;
}

.btn span {
  flex: 1;
  text-align: center;
}

.btn:hover {
  background: #615858;
  color: rgba(255, 255, 255, 1);
}

.mid-or {
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
}

.bottom-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: none;
  height: fit-content;
}

.bottom-section p {
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  bottom: 0;
  width: 100%;
}

.heart-icon {
  color: red;
}

@media only screen and (max-width: 426px) {
  .login-wrapper {
    width: 100vw;
  }

  .container {
    width: 100%;
    padding: 11px 20px;
  }

  .top-section {
    gap: 30px;
  }

  .logo img {
    width: 150px;
    height: 150px;
  }

  .mid-section {
    gap: 10px;
  }

  .p1 {
    font-size: 24px;
  }

  .p2 {
    font-size: 20px;
  }

  .p3 {
    font-size: 20px;
  }

  .btn {
    width: 80%;
    height: fit-content;
    padding: 2px;
    font-size: 16px;
  }

  .btn-img {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 426px) {
  .container {
    padding: 11px 28px;
  }

  .top-section {
    gap: 30px;
  }

  .logo img {
    width: 150px;
    height: 150px;
  }

  .mid-section {
    gap: 10px;
  }

  .p1 {
    font-size: 24px;
  }

  .p2 {
    font-size: 20px;
  }

  .p3 {
    font-size: 20px;
  }

  .btn {
    height: fit-content;
    padding: 4px;
    font-size: 18px;
  }

  .btn-img {
    width: 35px;
    height: 35px;
  }
}

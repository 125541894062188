.delete-circle-modal {
  width: 340px;
  max-height: 550px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  gap: 10px;
  position: relative;
  overflow: hidden;
}

.dcm-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  width: 100%;
}

.dcm-title {
  font-size: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  width: 100%;
}

.dcm-close {
  position: absolute;
  right: 10px;
  top: 5px;
}

.dcm-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.dcm-confirm-msg {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}

.scroll-when-move {
  overflow: hidden;
  overflow-y: auto;
  max-height: 350px;
}

.scroll-when-move::-webkit-scrollbar {
  width: 0;
}

.dcm-delete-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.acp-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.dcm-radio {
  accent-color: black;
  width: 15px;
  height: 15px;
}

.dcm-option-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.dcm-option-title {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  color: #000000;
}

.dcm-option-desc {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 12px;
  font-weight: lighter;
  color: #646464;
  padding-left: 25px;
}

.dcm-move-canvas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.move-con-title {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
}

.all-con-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  max-height: 130px;
  gap: 10px;
}

.all-con-body::-webkit-scrollbar {
  width: 5px;
}

.all-con-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.all-con-body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.all-con-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.all-con-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}

.con-profile-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  background-color: #c2c2c2;
  border-radius: 50%;
}

.con-name {
  font-size: 12px;
}

.mc-select-circle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.mc-move-circle {
  font-size: 14px;
  font-weight: bold;
}

.move-to-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding-bottom: 2px;
}

.mtc-create-new {
  width: 100%;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.mtc-radio,
.mtc-add-new {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 15px;
  width: 100%;
  padding-left: 30px;
  font-size: 16px;
}

.mtc-add-new {
  padding-left: 0;
}

.mtc-radio-btn {
  transform: scale(1.3);
  display: flex;
  align-items: center;
  justify-content: center;
  accent-color: rgb(75, 75, 75);
  border: none;
  outline: none;
}

.mtc-radio-label {
  font-size: 14px;
}

.mtc-created-circles {
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
}

.dcm-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dcm-footer-delete {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.dcm-delete-btn,
.move-to-btn {
  width: 100px;
  padding: 5px 10px;
  border-radius: 25px;
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
}

.move-to-btn {
  width: fit-content;
  font-size: 16px;
  padding: 5px 20px;
}

.dcm-delete-btn:hover {
  background-color: rgb(27, 27, 27);
}

.add-custom-circle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.confirm-delete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cd-close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
}

.cd-circle-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cd-circle-type {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.cd-circle-connections {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.cd-con-icon {
  font-size: 14px;
}

.cd-con-count {
  font-size: 14px;
  font-weight: bold;
}

.cd-retype {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.cd-type-text {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 12px;
}

.cd-type-text-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(205, 205, 205);
  font-size: 12px;
  padding-left: 10px;
  height: 30px;
  outline: none;
}

.confirm-delete-error {
  color: red;
  font-size: 12px;
}

.cd-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-delete-btn {
  padding: 5px 20px;
  border-radius: 25px;
  color: white;
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  cursor: pointer;
  transition: 0.3s;
}

.confirm-delete-btn:hover {
  background-color: #000000;
}

.progress-move-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 280px;
  border-radius: 10px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0);
}

.progress-move-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-move-desc,
.progress-move-delete {
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: bold;
}

.progress-move-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.progress-bar-track {
  background-color: rgb(122, 122, 122);
  width: 100%;
  height: 5px;
  border-radius: 25px;
}

.progress-bar-thumb {
  background-color: #000000;
  border-radius: 25px;
}

.progress-move-count {
  font-size: 12px;
}

.delete-confirmed {
  position: fixed;
  top: 50%;
  left: 50%;
  transition: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 375px) {
  .delete-circle-modal {
    width: 290px;
  }
  .confirm-delete-modal {
    padding: 10px;
  }
}

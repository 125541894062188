.options {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f6f6f6;
  cursor: pointer;
  transition: 0.3s;
  font-size: 20px;
  color: #767676;
}

.options:hover {
  background-color: #e7e7e7;
}

.options-popup {
  width: 300px;
  height: fit-content;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  position: relative;
}

.options-popup-header {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
}

.options-title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.options-close {
  position: absolute;
  right: 10px;
  top: 0;
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  border: none;
  outline: none;
}

.options-popup-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.options-label {
  width: 100%;
  display: flex;
  text-align: left;
  font-size: 14px;
}

.link-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: #c9c0c0;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 5px 0;
}

.shortly-icon {
  width: 60px;
  height: 40px;
  border-right: 1px solid rgb(215, 215, 215);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.link-text {
  width: 100%;
  border: none;
  outline: none;
  padding-left: 10px;
}

.options-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.update {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 90px;
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  color: white;
  text-align: center;
  border-radius: 25px;
}
.remove {
  cursor: pointer;
  border: 2px solid rgb(195, 195, 195);
  background-color: transparent;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
  width: 90px;
}

.update:hover {
  background: rgb(27, 27, 27);
}

.remove:hover {
  background: rgb(220, 220, 220);
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.sm-links-canvas {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: auto;
}

.sm-links-added {
  width: 90%;
  height: 55px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px;
}

.template-fill {
  background-color: white;
}

.template-border {
  background-color: transparent;
  border: 2px solid rgb(255, 255, 255, 70%);
}

.template-border .options {
  background-color: rgba(0, 0, 0, 0.08);
  color: #000000;
  transition: 0.3s;
}

.template-border .options:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.template-border .custom-options {
  background-color: rgba(0, 0, 0, 0.04);
  color: #000000;
  transition: 0.3s;
}

.template-border .custom-options:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: #000000;
}

.custom-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.sm-links-logo {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 25px;
  background-color: black;
  color: white;
}

.sm-links-title {
  flex: 1;
  text-align: center;
  font-size: 24px;
  color: #000000;
}

.custom-options {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f6f6f6;
  cursor: pointer;
  transition: 0.3s;
  font-size: 20px;
  color: #767676;
}
.custom-options:hover {
  background-color: #e7e7e7;
}

.guest-view-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
}

.view-as-guest-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: sticky;
  top: 10px;
  z-index: 9999;
}

.view-as-guest {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-weight: bold;
  border-radius: 10px;
  padding: 5px;
  gap: 10px;
}

.view-as-guest.view-as-guest-active {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.view-as-guest.view-as-guest-not-active {
  background-color: rgba(0, 0, 0, 0.05);
  color: white;
}

.view-as-guest-icon {
  left: 10px;
  font-size: 30px;
  cursor: pointer;
}

.view-as-guest-desc {
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
  font-size: 20px;
  cursor: pointer;
}

.guest-options-modal {
  position: fixed;
  width: 280px;
  top: 40%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
}

.gom-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.gom-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  height: 60px;
}

.gom-close {
  position: absolute;
  right: 5px;
  top: 3px;
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.gom-body {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.gom-sm-link {
  width: 100%;
  border: 1px solid rgb(202, 202, 202);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  height: 45px;
  position: relative;
}

.gom-sm-link-text {
  font-size: 16px;
  max-width: 80%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.gom-sm-link-text::-webkit-scrollbar {
  height: 0;
}

.gom-sm-copy {
  position: absolute;
  right: 10px;
  color: #787878;
  font-size: 14px;
  cursor: pointer;
}

.guest-sites-options {
  position: fixed;
  width: 280px;
  gap: 10px;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  z-index: 1000;
}

.gso-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 20px;
}

.guest-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 11px 20px;
  width: 428px;
  gap: 10px;
  position: relative;
}

.navbar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0 10px;
  position: sticky;
  top: 10px;
  z-index: 999;
}

.navbar-active {
  background-color: rgba(0, 0, 0, 70%);
}

.navbar-active .home-options-popup {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.navbar-active .settings-popup {
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
}

.home-btn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}

.home-t-logo {
  width: 33px;
  height: 33px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-options-btn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.profile-options-icon {
  color: white;
  font-size: 22px;
}

.profile-top {
  flex: none;
  height: fit-content;
}

.section1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 55px;
  margin-bottom: 5px;
}

.connections {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
}

.connection-count {
  color: white;
  width: 37px;
  height: 18px;
  border-radius: 5px;
  padding: 3px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  transform: translateY(50%);
}

.guest-profile {
  position: relative;
  margin-bottom: 10px;
}

.guest-profile-image {
  height: 112px;
  width: 112px;
  border-radius: 50%;
  background-size: cover;
  background-color: #e8e6e6;
  overflow: hidden;
  border: 5px solid #e8e6e6;
  cursor: pointer;
}

.guest-profile-image img {
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.share-profile {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.share-icon {
  color: white;
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.report-icon {
  font-size: 18px;
  color: white;
  position: absolute;
  right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.section3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
}

.sites-wrapper {
  width: 28px;
  height: 28px;
  border: solid 1px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.sites {
  color: rgb(255, 255, 255);
  font-size: 18px;
}

.sites-view-popup {
  background-color: #007485;
  position: fixed;
  color: white;
  width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  top: 230px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  z-index: 99;
}

.sites-vp-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-bottom: 1px solid #2f8895;
  padding: 10px 0;
}

.sites-vp-close {
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
}

.sites-vp-body {
  width: 100%;
}

.sites-vlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: 1px;
  width: 100%;
  cursor: pointer;
  padding: 20px 0;
  color: white;
  gap: 20px;
  overflow: hidden;
}

.sites-vlist a {
  text-decoration: none;
}

.sites-vlist-no-sites {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: 1px;
  padding: 20px 0;
  color: white;
}

.site-name {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 20px;
}

.sites-options {
  width: 30px;
  height: 30px;
  right: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(246, 246, 246, 0.041);
  cursor: pointer;
  transition: 0.3s;
  font-size: 20px;
  color: #767676;
}

.sites-options:hover {
  background-color: rgba(246, 246, 246, 0.253);
  color: #606060;
}

.section4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.guest-bio {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 80%);
  font-weight: 300;
  font-size: 15px;
}

.section5-guest {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.demographic-guest {
  gap: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
}

.demo-icon {
  width: 16px;
  height: 16px;
}

.guest-demo-desc {
  font-size: 10px;
  color: rgba(255, 255, 255, 90%);
}

.options {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f6f6f6;
  cursor: pointer;
  transition: 0.3s;
  font-size: 20px;
  color: #767676;
}

.share-profile-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.copied-text {
  font-size: 16px;
}

.guest-qr {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
}

.guest-body-qr-code {
  width: 200px;
  height: 200px;
  padding: 10px;
  border: 2px solid rgb(233, 233, 233);
  border-radius: 10px;
}

.download-qr {
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.441);
  position: absolute;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.download-qr-icon1,
.download-qr-icon2 {
  background-color: rgb(239, 239, 239);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  object-fit: cover;
  font-size: 38px;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.guest-qr:hover .download-qr {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s, visibility 0.3s ease-in-out;
}

.spp-share-msg {
  width: 100%;
}

.spp-share-own,
.spp-share-other {
  width: 100%;
  padding: 5px;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 5px;
}

.ssm-edit {
  border: none;
  width: 100%;
  outline: none;
}

.spp-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spp-share-btn {
  border: none;
  outline: none;
  color: white;
  background-color: rgb(52, 52, 52);
  padding: 5px 20px;
  transition: 0.4s;
  border-radius: 10px;
  cursor: pointer;
}

.spp-share-btn:hover {
  background-color: black;
}

.acp-created-circles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.acp-create-new {
  width: 100%;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.guest-shortlys {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  gap: 20px;
  min-height: 250px;
}

.created-circle-name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  gap: 15px;
}

.acp-radio-btn {
  transform: scale(1.3);
  display: flex;
  align-items: center;
  justify-content: center;
  accent-color: rgb(75, 75, 75);
  border: none;
  outline: none;
}

.acp-radio-label {
  font-size: 14px;
}

.no-circles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.no-circles-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
}

.guest-image-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 400px 200px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 400px 200px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 0 400px 200px rgba(0, 0, 0, 0.35);
  width: 200px;
  height: 200px;
  padding: 3px;
  border-radius: 50%;
  background-color: white;
  z-index: 99;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.view-guest-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.no-guest-image {
  width: 95%;
  height: 95%;
  border-radius: 50%;
}

.guest-sp-modal-own .spp-url2,
.guest-sp-modal-own .spp-url-copyicon2,
.guest-sp-modal-own .spp-body-qr-code2,
.guest-sp-modal-own .download-qr-icon2,
.guest-sp-modal-own .spp-share-other,
.guest-sp-modal-other .spp-url1,
.guest-sp-modal-other .spp-url-copyicon1,
.guest-sp-modal-other .spp-body-qr-code1,
.guest-sp-modal-other .download-qr-icon1,
.guest-sp-modal-other .spp-share-own {
  display: none;
}

.view-as-guest-error {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: white;
  padding: 20px;
  z-index: 999;
  border-radius: 5px;
  box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.21);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.guest-error-desc {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
}

.guest-error-btn {
  font-size: 14px;
  border: 2px solid rgb(134, 134, 134);
  padding: 3px 10px;
  border-radius: 20px;
  cursor: pointer;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.guest-error-btn:hover {
  color: white;
  background-color: rgb(51, 51, 51);
  border: 2px solid rgb(51, 51, 51);
}

.guest-user-add {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e8e6e6;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.guest-user-add.plus {
  color: rgb(0, 0, 158);
}

.guest-user-add.check {
  color: rgb(2, 134, 0);
}

@media only screen and (max-width: 768px) {
  .share-profile-popup {
    width: 280px;
    max-height: 450px;
  }

  .spp-header {
    padding-bottom: 5px;
  }

  .spp-title {
    font-size: 18px;
  }

  .spp-copy-url {
    height: 35px;
  }

  .spp-url-copyicon1,
  .spp-url-copyicon2 {
    font-size: 18px;
  }

  .copied-text {
    font-size: 12px;
  }

  .guest-body-qr-code {
    width: 130px;
    height: 130px;
  }

  .download-qr {
    width: 130px;
    height: 130px;
  }

  .spp-share-own,
  .spp-share-other {
    padding: 10px;
  }

  .ssm-edit {
    font-size: 12px;
    width: 100%;
    letter-spacing: -0.5px;
  }
}

@media only screen and (max-width: 1025px) {
  .share-profile-popup {
    width: 300px;
    max-height: 480px;
  }

  .spp-header {
    padding-bottom: 5px;
  }

  .spp-title {
    font-size: 20px;
  }

  .spp-copy-url {
    height: 35px;
  }

  .spp-url-copyicon1,
  .spp-url-copyicon2 {
    font-size: 18px;
  }

  .copied-text {
    font-size: 14px;
  }

  .spp-body-qr-code1,
  .spp-body-qr-code2 {
    width: 130px;
    height: 130px;
  }

  .download-qr {
    width: 130px;
    height: 130px;
  }

  .spp-share-own,
  .spp-share-other {
    padding: 10px;
  }

  .ssm-edit {
    font-size: 14px;
    width: 100%;
    letter-spacing: -0.5px;
  }
}

@media only screen and (max-width: 426px) {
  .guest-view-wrapper {
    padding: 11px 0;
  }

  .guest-view-container {
    width: 100%;
  }

  .section1 {
    gap: 55px;
  }

  .guest-profile-image {
    height: 100px;
    width: 100px;
  }

  .share-profile {
    width: 42px;
    height: 42px;
  }

  .share-icon {
    width: 23px;
    height: 23px;
  }

  .sites-wrapper {
    width: 30px;
    height: 30px;
  }

  .sites-view-popup {
    top: 200px;
  }

  .guest-bio {
    font-size: 14px;
  }

  .demo-icon {
    width: 14px;
    height: 14px;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: "Inria Sans", sans-serif;
}

button{
  border: none;
  outline: none;
  background: none;
}
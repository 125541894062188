.circle-limit-error-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  background-color: white;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 10px;
  z-index: 9999;
}

.limit-circle-header {
  width: 100%;
  height: 30px;
}

.limit-close {
  background: none;
  border: none;
  top: 5px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}

.limit-circle-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding: 0 20px;
}

.circle-upgrade-msg {
  font-size: 14px;
  display: flex;
  text-align: center;
  width: 100%;
}

.limit-circle-footer {
  padding-bottom: 10px;
}

.limit-circle-upgrade {
  width: fit-content;
  padding: 5px 20px;
  border-radius: 10px;
  color: white;
  background-color: #3b3b3b;
  cursor: pointer;
}

.limit-circle-upgrade:hover {
  background-color: #000000;
}

@media only screen and (max-width: 375px) {
  .circle-limit-error-modal {
    width: 300px;
  }
}

.social-share-popup {
  overflow: hidden;
  width: 330px;
  height: auto;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
}

.ss-box {
  width: 330px;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.ss-title {
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  height: 100%;
}

.ss-close-btn {
  background: none;
  border: none;
  top: 3px;
  right: 10px;
  position: absolute;
}

.social-popup-body {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.social-popup-body-canvas {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.share-icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
}

.share-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.copy-link-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px;
  background-color: rgb(79, 72, 72);
  border-radius: 50%;
  color: white;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.success-copied {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, -50%);
  width: 200px;
  box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.32);
  background-color: white;
  border-radius: 10px;
  z-index: 999;
}

.success-text {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.success-icon {
  font-size: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(8, 116, 55);

}

@media screen and (max-width: 426px) {
  .social-share-popup {
    width: 250px;
    text-align: center;
  }

  .ss-box {
    width: 250px;
    height: 60px;
  }

  .social-popup-body {
    width: 250px;
  }

  .ss-title {
    font-size: 22px;
    text-align: center;
  }
}

@media screen and (max-width: 769px) and (min-width: 427px) {
  .social-share-popup {
    width: 280px;
    text-align: center;
  }

  .ss-box {
    height: 60px;
    width: 280px;
  }

  .social-popup-body {
    width: 280px;
  }
}

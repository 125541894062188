.bio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#0ddfff, #30c027);
  height: 100vh;
  padding: 10px 28px;
  overflow: auto;
}

.bio-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 11px 28px;
  width: 428px;
  gap: 10px;
}

.bio-header {
  width: 100%;
  flex: none;
  height: fit-content;
}

.bio-header .progress-header {
  width: 100%;
  flex: none;
  height: fit-content;
}

.bio-header .ul-box2 {
  background: rgba(33, 205, 130, 1);
}

.bio-desc-body {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bb-wrapper {
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.bb-topic {
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 26px;
}

.bb-sub-topic {
  width: 100%;
  font-size: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  color: #f3f3f3;
}

.add-photo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-bio {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
}

.bio-text {
  width: 100%;
  height: 70px;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  margin: 0;
}

.bio-text::placeholder {
  color: #cacaca;
}

.char {
  border: none;
  outline: none;
  width: 100%;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  color: rgb(118, 118, 118);
  font-size: 12px;
  padding-right: 10px;
}

.bb-button {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bb-button button {
  border: none;
  background: none;
  font-size: 20px;
  font-weight: bold;
  color: #e7f0ee;
  text-align: center;
}

.bb-button button:hover {
  font-size: 21px;
}

.bb-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bb-footer p {
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  bottom: 0;
  width: 100%;
  padding: 0;
  margin: 0;
}

.heart-icon {
  color: red;
}

@media only screen and (max-width: 426px) {
  .bio-container {
    padding: 0;
    width: 100vw;
  }

  .bio-wrapper {
    padding: 11px 28px;
    width: 100%;
  }

  .bb-wrapper {
    gap: 20px;
  }

  .bb-topic {
    font-size: 20px;
  }

  .bb-sub-topic {
    font-size: 18px;
  }

  .bio-text {
    height: 74px;
    font-size: 14px;
    padding-left: 10px;
  }

  .add-bio span {
    font-size: 12px;
  }

  .bb-button button {
    font-size: 18px;
  }

  .bb-footer p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 426px) {
  .bio-container {
    padding: 0;
  }

  .bio-wrapper {
    padding: 11px 28px;
    width: 428px;
  }

  .bb-wrapper {
    gap: 20px;
  }

  .bb-topic {
    font-size: 20px;
  }

  .bb-sub-topic {
    font-size: 18px;
  }

  .bio-text {
    height: 74px;
    font-size: 14px;
    padding-left: 10px;
  }

  .add-bio span {
    font-size: 12px;
  }

  .bb-button button {
    font-size: 18px;
  }

  .bb-footer p {
    font-size: 14px;
  }
}

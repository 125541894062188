.add-pl {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  border: 3px solid rgba(255, 255, 255, 70%);
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-pl-icon {
  color: rgba(255, 255, 255, 70%);
  font-size: 18px;
}

.pl-popup {
  width: 354px;
  max-height: calc(100vh - 150px);
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  background: rgba(255, 255, 255);
  text-align: center;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upgrade-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pl-toggle-alert {
  position: absolute;
  width: 90%;
  border-radius: 10px;
  top: 150px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.plt-alert-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
}

.plt-alert-text {
  font-size: 14px;
  color: #676767;
  font-weight: lighter;
}

.plt-upgrade-alert {
  font-weight: bold;
  font-size: 14px;
}

.plt-upgrade-button {
  cursor: pointer;
  padding: 5px 20px;
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  color: white;
  border-radius: 10px;
}

.plt-upgrade-button:hover {
  background: rgb(27, 27, 27);
}

.pl-popup-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 230px);
}

.pl-box {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
}

.pl-title {
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  height: 60px;
}

.select-pl {
  font-size: 16px;
}

.select-pl-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.select-pl-option {
  gap: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  text-align: center;
  color: #7a7a7a;
}

.pl-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.radio-btn {
  width: 16px;
  height: 16px;
  accent-color: black;
}

.selected-pl-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

hr {
  color: #c0c0c0;
}

.selected-pl {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.select-pl-title {
  font-size: 16px;
  color: #5a5a5a;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
}

.select-pl-text {
  width: 100%;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  gap: 5px;
}

.toggle-label {
  font-size: 12px;
  color: #afafaf;
  font-weight: bold;
}

.select-pl-icon {
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-pl-icon img {
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.select-pl-icon-img {
  font-size: 35px;
  padding: 5px;
  background-color: black;
  border-radius: 8px;
  color: white;
  margin-left: 3px;
}

.pl-popup-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
}

.pl-add-btn {
  width: 96px;
  height: 35px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #ffffff;
  background-color: rgb(36, 36, 36);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pl-add-btn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.add-btn:hover {
  background: rgb(0, 0, 0);
}

.flag-dropdown {
  font-size: 10px;
  text-align: left;
}

.pl-input-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
}

.pl-info-icon {
  border-radius: 50%;
  color: rgb(108, 108, 108);
  background-color: #ededed;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  cursor: pointer;
}

.pl-info-desc {
  position: absolute;
  width: 200px;
  font-size: 10px;
  background-color: #dddddd;
  border-radius: 5px;
  right: 15px;
  top: -10px;
  padding: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}

.pl-info-icon:hover + .pl-info-desc {
  opacity: 1;
  visibility: visible;
}


.select-pl-input {
  width: 100%;
  height: 40px;
  border: none;
  background-color: #f5f5f5;
  outline: none;
  border-radius: 5px;
  padding-left: 10px;
}

@media only screen and (max-width: 768px) {
  .add-pl {
    width: 30px;
    height: 30px;
  }

  .pl-popup {
    width: 300px;
  }

  .pl-popup-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .pl-title {
    font-size: 20px;
  }

  .select-pl-desc {
    gap: 20px;
  }

  .selected-pl {
    height: 320px;
    padding: 0 10px;
    gap: 0;
  }

  .select-pl-icon img {
    width: 35px;
    height: 35px;
  }

  .select-pl-title {
    font-size: 14px;
  }

  .select-pl-input{
    font-size: 14px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 769px) {
  .pl-popup {
    width: 280px;
    max-height: 450px;
  }

  .pl-title {
    font-size: 18px;
  }

  .pl-box {
    height: 60px;
  }

  .selected-pl {
    max-height: 300px;
    padding: 0 20px;
  }

  .select-pl-desc {
    gap: 10px;
  }

  .select-pl-title {
    font-size: 14px;
  }

  .radio-btn {
    width: 14px;
    height: 14px;
  }

  .select-pl-icon img {
    width: 35px;
    height: 35px;
  }

  .select-pl-icon-img {
    font-size: 30px;
  }

  .react-tel-input .form-control,
  .select-pl-text .form-control,
  .pl-radio {
    font-size: 12px;
  }

  .pl-add-btn {
    font-size: 14px;
  }

  .pl-toggle-alert {
    top: 100px;
  }
}

.userLogin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(#0ddfff, #30c027);
  height: 100vh;
  padding: 10px 28px;
  overflow: auto;
}

.ul-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 11px 28px;
  width: 428px;
  gap: 10px;
}

.ul-header {
  width: 100%;
  flex: none;
  height: fit-content;
}

.ul-header .progress-header {
  width: 100%;
  flex: none;
  height: fit-content;
}

.ul-body {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.ulb-topic {
  width: 100%;
  font-size: 26px;
  text-align: center;
  justify-content: space-between;
  color: #ffffff;
  font-weight: bold;
}

.ulb-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.ulb-sub-topic {
  width: 100%;
  font-size: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  color: #f3f3f3;
}

.ul-text {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: space-between;
  gap: 10px;
}

.fname,
.lname {
  width: 100%;
}

.name-label {
  font-size: 14px;
  color: rgb(117, 117, 117);
  width: 100%;
  text-align: left;
}

.fname .name-text,
.lname .name-text {
  padding-left: 10px;
}

.name-text p {
  margin: 0;
  padding: 0;
}

.name-text::placeholder {
  color: #cacaca;
}

.fname .name-text::placeholder,
.lname .name-text::placeholder {
  padding-left: 10px;
}

.error-message {
  margin-top: 5px;
  font-size: 14px;
  color: #e7fff5;
}

.error-message p {
  margin: 0;
  padding: 0;
}

.uname {
  width: 100%;
}

.input-user-name {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-user-name span {
  width: 40px;
  height: 40px;
  color: #cacaca;
  background-color: white;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;
}

.name-text {
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 10px;
  padding-left: 0;
  height: 40px;
}

.uname .name-text {
  border-radius: 0 10px 10px 0;
}

.email {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.ul-body-note {
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #ffffff;
  margin: 0;
  padding: 5px;
  border-radius: 10px;
}

.ul-body-note p {
  margin: 0;
  padding: 0;
}

.ul-bottom {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ul-bottom button {
  border: none;
  background: none;
  font-size: 20px;
  font-weight: bold;
  color: #e7f0ee;
  text-align: center;
}

.ul-bottom button:hover {
  font-size: 21px;
}

.ul-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ul-footer p {
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
}

.heart-icon {
  color: red;
}

@media only screen and (max-width: 426px) {
  .userLogin-container {
    padding: 0;
    justify-content: flex-start;
    width: 100vw;
  }

  .ul-wrapper {
    width: 100%;
    padding: 11px 28px;
  }

  .ul-body {
    gap: 20px;
  }

  .ulb-topic {
    font-size: 20px;
  }

  .ulb-top {
    gap: 10px;
  }

  .ulb-sub-topic {
    font-size: 18px;
  }

  .ul-text {
    gap: 10px;
  }

  .name-label {
    font-size: 12px;
  }

  .name-text {
    font-size: 14px;
    padding-left: 10px;
    height: 35px;
  }

  .input-user-name span {
    height: 35px;
    width: 35px;
  }

  .name-text::placeholder {
    color: #cacaca;
  }

  .error-message {
    margin-top: 5px;
    font-size: 12px;
  }

  .email {
    font-size: 14px;
  }

  .ul-body-note {
    font-size: 14px;
    margin-top: 0;
    padding: 5px;
    height: fit-content;
  }

  .ul-body-note p {
    margin: 0;
    padding: 0;
  }

  .ul-bottom {
    bottom: 0;
  }

  .ul-bottom button {
    font-size: 18px;
  }

  .ul-bottom button:hover {
    font-size: 19px;
  }

  .ul-footer p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 426px) {
  .ul-wrapper {
    width: 428px;
  }

  .ul-body {
    gap: 20px;
  }

  .ulb-topic {
    font-size: 20px;
  }

  .ulb-top {
    gap: 10px;
  }

  .ulb-sub-topic {
    font-size: 18px;
  }

  .ul-text {
    gap: 10px;
  }

  .name-text {
    font-size: 14px;
    padding-left: 10px;
    height: 35px;
  }

  .name-text::placeholder {
    color: #cacaca;
  }

  .error-message {
    margin-top: 5px;
    font-size: 12px;
  }

  .ul-body-note {
    font-size: 14px;
    margin-top: 0;
    padding: 10px;
    height: fit-content;
  }

  .ul-body-note p {
    margin: 0;
    padding: 0;
  }

  .ul-bottom button {
    font-size: 18px;
  }

  .ul-bottom button:hover {
    font-size: 19px;
  }

  .ul-footer p {
    font-size: 14px;
  }

  .input-user-name span {
    height: 35px;
  }
}

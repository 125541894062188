.shortlys-canvas {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.add-sm {
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 29%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.add-sm-icon {
  color: #f1f1f1;
  font-size: 38px;
}

.sm-popup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 384px;
  padding: 5px 5px 20px 5px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  background: rgba(255, 255, 255, 1);
  position: relative;
  text-align: center;
  max-height: calc(100vh - 150px);
}

.smp-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
}

.smp-close {
  position: absolute;
  right: 15px;
  top: 5px;
}

.smp-close-btn {
  background: none;
  border: none;
  float: right;
}

.smp-title {
  font-size: 24px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.smp-buttons {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 30px;
  justify-content: center;
  padding: 0 10px;
}

.smp-btn {
  width: 150px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid rgb(210, 210, 210);
  background: transparent;
  transition: 0.2s;
}

.smp-btn.active {
  background-color: rgb(129, 129, 129);
  color: white;
}

.smp-btn:hover {
  background-color: rgb(129, 129, 129);
  color: white;
}

.smp-body {
  height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.smp-instruction,
.smp-instruction-bottom {
  width: 90%;
  font-size: 14px;
  color: #909090;
  padding: 10px 0;
}

.smp-instruction-bottom {
  width: 100%;
}

.smp-scroll {
  height: auto;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 10px;
}

.smp-scroll::-webkit-scrollbar {
  width: 5px;
}

.smp-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.smp-scroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.smp-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.custom-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  gap: 20px;
  max-height: 300px;
  overflow: auto;
}

.input-sec {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.cb-input-title {
  display: flex;
  align-items: center;
  text-align: left;
}

.input-sec label {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: rgb(0, 0, 0);
}

.cl-input-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.txt-url {
  border: 1px solid rgb(225, 225, 225);
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  outline: none;
}

.input-sec-required {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.required-cl {
  color: red;
}

.txt-url::placeholder {
  color: rgb(176, 176, 176);
  font-size: 14px;
}

.txt-url:focus::placeholder {
  color: transparent;
}

.error-msg-custom-link {
  color: red;
  font-size: 14px;
  float: left;
}

.error-msg-sm-invalid {
  color: red;
  font-size: 14px;
  width: 100%;
  display: flex;
  text-align: left;
  background-color: green;
}

.add-img-sec {
  width: 100%;
  height: fit-content;
  border: 1px dashed rgb(176, 176, 176);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
}

.input-img {
  cursor: pointer;
}

.uploaded-img-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  gap: 20px;
}

.uploaded-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
}

.btn-group {
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 10px;
}

.btn-change,
.btn-remove {
  border-radius: 10px;
  border: 1px solid #d4d4d4;
  padding: 2px 0;
  outline: none;
}

.btn-change {
  background-color: #ebebeb;
}

.add-custom-btn {
  padding: 0;
  margin: 0;
}

.btn-remove {
  background-color: transparent;
}

.btn-change:hover {
  background-color: #dedede;
}

.btn-remove:hover {
  background-color: #ebebeb;
}

.upload-area {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.upload-icon {
  width: 50px;
  height: 50px;
  color: #cacaca;
}

.upload-desc,
.image-error {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #909090;
}

.image-error {
  color: red;
}

.smp-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.smp-save {
  width: 96px;
  height: 35px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(36, 36, 36);
}

.smp-save:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.smp-save:hover {
  background: rgb(0, 0, 0);
}

.add-sm-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 15px;
}

.select-box {
  width: 22px;
  height: 22px;
  accent-color: rgb(75, 75, 75);
}

.add-sm-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-msg-sm-links {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 37px;
  font-size: 12px;
  color: red;
  font-weight: lighter;
}

.add-sm-box {
  width: 300px;
  height: 40px;
  border: #c9c0c0;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.icon-box {
  border-right: 2px solid #b1adad;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.sm-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 20px;
  color: #7d7d7d;
}

.sm-url {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding-left: 10px;
  font-size: 16px;
}

.sm-url::placeholder {
  font-size: 16px;
  color: #a7a7a7;
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .sm-popup {
    gap: 20px;
    width: 300px;
    max-height: 470px;
  }

  .smp-header {
    height: 50px;
  }

  .smp-title {
    font-size: 18px;
  }

  .smp-buttons {
    gap: 15px;
  }

  .smp-btn {
    width: 120px;
    padding: 5px;
    font-size: 12px;
  }

  .smp-body {
    max-height: 300px;
    width: 100%;
  }

  .smp-instruction {
    font-size: 12px;
    padding-bottom: 10px;
  }

  .smp-scroll {
    padding: 5px 10px;
    width: 100%;
  }

  .add-sm-link {
    width: 100%;
  }

  .add-sm-box {
    width: 100%;
  }

  .sm-url::placeholder {
    font-size: 12px;
  }

  .input-sec label {
    font-size: 14px;
  }

  .txt-url::placeholder {
    font-size: 12px;
  }

  .upload-desc {
    font-size: 12px;
  }

  .smp-save,
  .smp-cancel {
    width: 86px;
    height: 30px;
    font-size: 16px;
  }

  .custom-body {
    gap: 10px;
  }

  .cb-input-title {
    font-size: 12px;
    display: flex;
    text-align: left;
  }
}

@media screen and (max-width: 1025px) and (min-width: 427px) {
  .sm-popup {
    gap: 15px;
    width: 330px;
    max-height: 460px;
    padding: 20px 10px;
  }

  .smp-header {
    height: 40px;
  }

  .smp-title {
    font-size: 18px;
  }

  .smp-body {
    height: 300px;
    width: 100%;
  }

  .smp-instruction {
    padding-bottom: 10px;
  }

  .smp-scroll {
    padding: 5px 10px;
    width: 100%;
  }

  .add-sm-link {
    width: 100%;
  }

  .add-sm-box {
    width: 100%;
  }

  .smp-btn {
    width: 140px;
    font-size: 14px;
  }

  .smp-buttons {
    gap: 20px;
  }

  .custom-body {
    gap: 10px;
  }

  .cb-input-title {
    font-size: 12px;
    display: flex;
    text-align: left;
  }
}

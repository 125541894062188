.sites-options {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(246, 246, 246, 0.041);
  cursor: pointer;
  transition: 0.3s;
  font-size: 20px;
  color: #767676;
}

.sites-options:hover {
  background-color: rgba(246, 246, 246, 0.253);
  color: #606060;
}

.sites-options-popup {
  width: 300px;
  height: fit-content;
  padding: 20px 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  position: relative;
}

.sites-options-header {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
}

.sites-options-title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.sites-options-close {
  position: absolute;
  right: 10px;
  top: 0;
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  border: none;
  outline: none;
}

.sites-options-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.add-sites-input {
  width: 100%;
  align-items: center;
  gap: 5px;
}

.add-sites-input label {
  width: 100%;
  text-align: left;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

.add-site-text {
  border: 1px solid rgb(225, 225, 225);
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  outline: none;
}

.add-site-text::placeholder {
  color: rgb(176, 176, 176);
  font-size: 15px;
}

.add-site-text:focus::placeholder {
  color: transparent;
}

.error-msg-site-link {
  color: red;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 1px;
}

.add-site-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.site-update {
  width: 100px;
  padding: 5px 10px;
  border-radius: 25px;
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.site-remove {
  width: 100px;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 16px;
  border: 2px solid rgb(195, 195, 195);
  background-color: transparent;
  text-align: center;
  cursor: pointer;
}

.site-update:hover {
  background: rgb(27, 27, 27);
}

.site-remove:hover {
  background: rgb(220, 220, 220);
}

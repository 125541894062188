.scan-qr-popup {
  width: 350px;
  max-height: 350px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  position: fixed;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scan-qr-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(224, 223, 223);
  padding-bottom: 10px;
}

.scan-qr-title {
  font-size: 24px;
}

.scan-qr-close {
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
}

.scan-qr-body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px;
  position: relative;
}

.qr-reader {
  width: 160px;
  height: 160px;
  border-radius: 5px;
  position: relative;
  background-color: rgb(0, 0, 0);
}

.qr-reader::after {
  content: "";
  position: absolute;
  inset: 0 -10px 0 -10px;
  width: 180px;
  height: 2px;
  background-color: rgb(0, 255, 34);
  filter: drop-shadow(0 0 20px rgb(0, 255, 34))
    drop-shadow(0 0 60px rgb(0, 255, 34));
  overflow: visible;
  animation: animateLine 3s ease-in-out infinite;
}

@keyframes animateLine {
  0% {
    top: 1px;
  }
  50% {
    top: 100%;
  }
}

.qr-border {
  position: absolute;
  inset: 0;
  background: url(../../Assets/Border.png);
  background-size: 200px;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.qr-scanner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scan-qr-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.loading-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: black;
  border-radius: 50%;
  opacity: 0;
  animation: dot-sequence 1.5s infinite steps(1);
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot-sequence {
  0%,
  20% {
    opacity: 0;
  }
  30%,
  50% {
    opacity: 1;
  }
}

.qr-reader-msg{
  font-size: 16px;
  font-weight: bold;
}

.scan-qr-error-msg {
font-weight: normal;
font-size: 14px;
}

.scan-qr-error-topic{
  font-size: 18px;
  font-weight: bold;
}

.scan-qr-error,
.scan-camera-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  text-align: center;
}

.scan-camera-error{
  gap: 10px;
}

.scan-qr-msg {
  font-size: 16px;
}

.scan-qr-anime {
  width: 500px;
  height: 500px;
}

.scan-qr-btn {
  border: none;
  outline: none;
  color: white;
  background-color: rgb(52, 52, 52);
  padding: 5px 20px;
  transition: 0.4s;
  border-radius: 10px;
  cursor: pointer;
}

.scan-qr-btn:hover {
  background-color: black;
}

@media only screen and (max-width: 768px) {
  .scan-qr-popup {
    width: 280px;
  }

  .scan-qr-title {
    font-size: 20px;
  }
}

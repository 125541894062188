.profile-image {
  height: 112px;
  width: 112px;
  position: relative;
  border-radius: 50%;
  background-image: url(../../Assets/User.svg);
  background-size: cover;
  background-color: #e8e6e6;
  overflow: hidden;
  border: 5px solid #e8e6e6;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.photo-edit-popup {
  width: 350px;
  height: fit-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  gap: 20px;
  position: relative;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
}

.view-image-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 400px 200px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 400px 200px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 0 400px 200px rgba(0, 0, 0, 0.35);
  width: 200px;
  height: 200px;
  padding: 3px;
  background-color: #ffffff;
  border-radius: 50%;
  z-index: 99;
}

.view-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.view-image.user-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 10px;
}

.pep-header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.pep-title {
  flex: 1;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  height: 100%;
}

.pep-close-btn {
  width: fit-content;
  border: none;
  background-color: transparent;
  outline: none;
  position: absolute;
  top: 5px;
  right: 10px;
}

.pep-body {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0 20px;
}

.pep-body-left {
  width: 80px;
  height: 80px;
  border: 2px solid rgb(209, 209, 209);
  background-color: rgb(229, 229, 229);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pep-body-left .user-photo {
  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 3px;
}

.pep-body-left .user-photo.user-icon {
  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 10px;
}

.pep-body-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1;
}

.add-new,
.image-remove {
  width: 100%;
  border: 1px solid rgb(209, 209, 209);
  padding: 5px 0;
  text-align: center;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}

.add-new {
  background-color: rgb(238, 238, 238);
}

.add-new-btn {
  padding: 0;
  margin: 0;
}

.image-remove {
  background-color: transparent;
}

.add-new:hover,
.image-remove:hover {
  background-color: rgb(224, 224, 224);
}

.pep-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.save-btn {
  width: 80px;
  height: 30px;
  border: rgba(58, 58, 58, 1);
  background-color: rgba(58, 58, 58, 1);
  color: white;
  border-radius: 10px;
  transition: 0.2s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-btn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.pep-footer .spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.save-btn:hover {
  background-color: black;
}

.add-image-file {
  background-color: rgba(0, 0, 0, 0.295);
  position: absolute;
  bottom: 0;
  outline: none;
  color: transparent;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.5s;
  border: none;
}

.add-image-file::-webkit-file-upload-button {
  visibility: hidden;
}

.add-image-icon {
  width: 100%;
  position: absolute;
  bottom: 5px;
  cursor: pointer;
}

.profile-image.image-added {
  border: 2px solid white;
}

.profile-image.image-added .add-image-file {
  opacity: 0;
}

@media only screen and (max-width: 1025px) {
  .profile-image {
    height: 100px;
    width: 100px;
    position: relative;
    border-radius: 50%;
    background-image: url(../../Assets/User.svg);
    background-size: cover;
    background-color: #e8e6e6;
    overflow: hidden;
    border: 5px solid #e8e6e6;
  }

  .add-image-file {
    background-color: rgba(0, 0, 0, 0.295);
    position: absolute;
    bottom: 0;
    outline: none;
    color: transparent;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    transition: 0.5s;
  }

  .add-image-file::-webkit-file-upload-button {
    visibility: hidden;
  }

  .add-image-icon {
    width: 100%;
    position: absolute;
    bottom: 5px;
    cursor: pointer;
  }

  .profile-image.image-added {
    border: none;
  }

  .profile-image.image-added .add-image-file {
    opacity: 0;
  }
}

.profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
}

.page-refresh-loading {
  width: fit-content;
  padding: 30px;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  color: rgb(255, 255, 255);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-sp-modal-own,
.user-sp-modal-other {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  top: 80px;
}

.user-sp-modal-own .spp-url2,
.user-sp-modal-own .spp-url-copyicon2,
.user-sp-modal-own .spp-body-qr-code2,
.user-sp-modal-own .download-qr-icon2,
.user-sp-modal-own .spp-share-other,
.user-sp-modal-other .spp-url1,
.user-sp-modal-other .spp-url-copyicon1,
.user-sp-modal-other .spp-body-qr-code1,
.user-sp-modal-other .download-qr-icon1,
.user-sp-modal-other .spp-share-other {
  display: none;
}

.scan-qr-modal {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  top: 150px;
}

.logout-confirm-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 250px;
  padding: 20px;
  z-index: 100;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
}

.lcm-msg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}

.lcm-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.lcm-cancel-btn,
.lcm-logout-btn {
  width: 80px;
  padding: 5px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lcm-cancel-btn {
  border: 2px solid rgb(195, 195, 195);
}

.lcm-logout-btn {
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  color: white;
  text-decoration: none;
}

.default-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 11px 20px;
  width: 428px;
  gap: 10px;
  position: relative;
}

.isNavbar,
.navbar-as-guest {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0 10px;
  position: sticky;
  z-index: 999;
}

.isNavbar {
  top: 10px;
}

.navbar-as-guest {
  top: 60px;
}

.isNavbar-active {
  background-color: rgba(0, 0, 0, 70%);
}

.isNavbar-active .home-options-popup {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.isNavbar-active .hop-settings-sec:hover,
.isNavbar-active .hop-widgets-sec:hover,
.isNavbar-active .hop-logout-sec:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.isNavbar-active .settings-popup {
  box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 10px 10px 400px 200px rgba(0, 0, 0, 0.19);
}

.home-btn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}

.home-t-logo {
  width: 33px;
  height: 33px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-options-btn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.profile-options-icon {
  color: white;
  font-size: 22px;
}

.home-options-popup {
  width: 150px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  top: 55px;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 122px 200px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 0 122px 200px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 122px 200px rgba(0, 0, 0, 0.03);
}

.hop-settings-sec,
.hop-widgets-sec,
.hop-logout-sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;
  transition: 0.3s;
}

.hop-settings-label,
.hop-widgets-label,
.hop-logout-label {
  width: 100%;
  flex: 1;
  padding-left: 10px;
}

.hop-settings-icon,
.hop-widgets-icon,
.hop-logout-icon {
  padding-right: 10px;
}

.hop-settings-sec:hover,
.hop-widgets-sec:hover,
.hop-logout-sec:hover {
  background-color: rgb(246, 246, 246);
}

.profile-top {
  flex: none;
  height: fit-content;
}

.section1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 55px;
  margin-bottom: 5px;
}

.connections {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
}

.connection-count {
  color: white;
  width: 37px;
  height: 18px;
  border-radius: 5px;
  padding: 3px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  transform: translateY(50%);
}

.share-profile {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.share-icon {
  color: white;
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.section2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  position: relative;
}

.section2guest {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.name-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.guest-view-icon {
  position: absolute;
  left: 50px;
  color: #626262;
  cursor: pointer;
}

.name {
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.uname-report {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  width: 100%;
  text-align: center;
}

.user-name {
  color: #707070;
  font-size: 18px;
}

.section3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
}

.sites-wrapper-d {
  width: 28px;
  height: 28px;
  border: solid 1px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.sites-d {
  color: rgb(255, 255, 255);
  font-size: 18px;
}

.sites-view-popup {
  background-color: #007485;
  position: fixed;
  color: white;
  width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  top: 230px;
  box-shadow: 0 0 122px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 122px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 122px 0 rgba(0, 0, 0, 0.2);
}

.sites-vp-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-bottom: 1px solid #2f8895;
  padding: 10px 0;
}

.sites-vp-close {
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
}

.sites-vp-body {
  width: 100%;
}

.sites-vlist {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 20px 0;
  overflow: hidden;
}

.site-name {
  color: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-left: 20px;
}

.dsite-option {
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  padding-right: 10px;
}

.sites-options-icon {
  color: rgb(235, 235, 235);
}

.sites-vlist:hover {
  background-color: rgb(0, 123, 142);
}

.sites-vlist-no-sites {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: 1px;
  padding: 20px 0;
  color: white;
}

.section4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 15px;
}

.section5 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 15px;
  width: 100%;
}

.profile-body {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.tab-section-shortlys,
.tab-section-circles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 160px;
  cursor: pointer;
}

.tab-section-shortlys.active-shortlys .bar {
  width: 100px;
  background-color: white;
  height: 1px;
}

.tab-section-circles.active-circles .bar {
  width: 100px;
  background-color: white;
  height: 1px;
}

.tab-image {
  width: 31px;
  height: 31px;
  cursor: pointer;
}

.tab-title {
  font-size: 10px;
  color: #5f5f5f;
}

.circles-view-body,
.add-links {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 20px;
}

.add-links {
  gap: 20px;
  position: relative;
}

.circles-view-body {
  gap: 50px;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.default-profile-smadd {
  height: auto;
  min-height: 250px;
}

.default-profile-smnot {
  height: 300px;
}

.default-profile-smnot .shortlys-canvas {
  position: absolute;
  top: 0;
}

.circle-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.circle-type {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.circle-type-title {
  font-size: 16px;
  font-weight: bold;
}

.circle-count {
  font-size: 14px;
}

.circle-icon {
  font-size: 16px;
  width: 22px;
  height: 22px;
}

.manage-circle-popup,
.add-new-circle-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.connect-with-another {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.cwa-circle-count {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: linear-gradient(#f600ff, #ff0076);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cwa-con-count {
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.cwa-circles {
  font-size: 18px;
  color: white;
}

.cwa-connect-btn,
.cwa-connect-btn-connected {
  width: 120px;
  height: 40px;
  color: white;
  background-color: #383838;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.cwa-connect-btn-connected {
  color: black;
  background-color: white;
}

.cwa-connect-btn:hover {
  background-color: #000000;
}

.Loading-effect {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
}

.add-sm,
.add-new-circle {
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 29%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-new-circle {
  background-color: transparent;
  border: 2px solid var(--dark-color);
}

.add-sm-icon {
  color: #f1f1f1;
  font-size: 38px;
}

.add-circle-icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.dp-sm-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.dp-social {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dp-p-links {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.add-pl {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  border: 3px solid rgba(255, 255, 255, 70%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-pl-icon {
  color: rgba(255, 255, 255, 70%);
  font-size: 18px;
}

.pl-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.pl-icon-sec {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  border: 3px solid rgba(255, 255, 255, 70%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pl-icon-img {
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
}

.pl-icon-sec-rio {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  border: 3px solid rgba(255, 255, 255, 70%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  object-fit: cover;
}

.pl-icon-img-rio {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 5px;
  position: relative;
}

.pl-view-modal {
  width: 250px;
  padding: 20px;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  gap: 20px;
}

.pl-view-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
}

.pl-view-type {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}

.pl-view-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.pl-copy-btn {
  color: #636363;
  cursor: pointer;
}

.pl-view-modal-upgrade {
  width: 280px;
  padding: 20px;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  gap: 20px;
}

.pl-modal-upgrade-close {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
}

.pl-upgrade-canvas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
}

.plv-upgrade-msg {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
}

.plv-upgrade-btn {
  width: 100px;
  height: 35px;
  color: white;
  background-color: #303030;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.plv-upgrade-btn:hover {
  background-color: #000000;
}

.fab {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  bottom: 20px;
  right: 28px;
  gap: 10px;
  z-index: 99;
}

.fab-menu {
  position: absolute;
  bottom: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 99;
  margin-bottom: 10px;
}

.sm-links-added {
  cursor: pointer;
}

.sm-links-added:active {
  opacity: 0.7;
  border: 1px solid #111;
}

.sm-links-added:hover {
  border: 2px solid #363636;
}

.fab-menu.open {
  visibility: visible;
  opacity: 1;
}

.share-own,
.qr,
.sites-add,
.notifications,
.user,
.dots {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  background-color: rgb(0, 0, 0, 53%);
  border: 4px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.share-own {
  background-color: rgba(31, 207, 143, 70%);
  background-size: 70%;
  border: solid 3px rgb(0, 0, 0, 53%);
  width: 55px;
  height: 55px;
  position: relative;
}

.share-own-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.fab-qr-icon {
  position: absolute;
  width: 38px;
  height: 38px;
  object-fit: cover;
}

.fab-user-photo {
  width: 54px;
  height: 54px;
  object-fit: cover;
}

.fab-user-photo-icon {
  width: 78%;
  height: 78%;
  object-fit: cover;
}

.dots .fab-icon {
  width: 55px;
  height: 55px;
}

.circle-container {
  border-radius: 50%;
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 0.7;
}

.circle-container li {
  position: absolute;
  left: 45px;
  list-style: none;
  transform: rotate(calc(360deg / var(--circle-count) * var(--i)));
  transform-origin: 130px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  z-index: 99;
}

.circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotate(calc(360deg / calc(-1 * var(--circle-count)) * var(--i)));
  width: 100%;
  gap: 10px;
  background: linear-gradient(var(--circle-template), var(--circle-template))
      padding-box,
    linear-gradient(var(--light-color), var(--dark-color)) border-box;
}

.circle-count,
.circle-name {
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(var(--circle-template), var(--circle-template))
      padding-box,
    linear-gradient(var(--light-color), var(--dark-color)) border-box;
}

.circle-container li.active {
  transform: rotate(calc(360deg / var(--circle-count) * var(--i)))
    translateX(-45px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  width: 200px;
  height: 1px;
  position: absolute;
  background-color: transparent;
  right: calc(33% + 1px);
  pointer-events: none;
  transition: 0.5s;
  transform-origin: right;
}

.indicator::before {
  position: absolute;
  content: "";
  top: -58px;
  right: 90px;
  width: 120px;
  height: 120px;
  background: linear-gradient(var(--circle-template), var(--circle-template))
      padding-box,
    linear-gradient(var(--light-color), var(--dark-color)) border-box;
  border: 8px solid transparent;
  transition: 0.5s;
  border-radius: 50%;
}

.circle-container li:nth-child(2).active ~ .indicator {
  transform: translateX(-60px) rotate(0deg);
}

.circle-container li:nth-child(3).active ~ .indicator {
  transform: translateX(-60px) rotate(calc(360deg / var(--circle-count)));
}

.circle-container li:nth-child(4).active ~ .indicator {
  transform: translateX(-60px) rotate(calc(360deg / var(--circle-count) * 2));
}

.circle-container li:nth-child(5).active ~ .indicator {
  transform: translateX(-60px) rotate(calc(360deg / var(--circle-count) * 3));
}

.circle-container li:nth-child(6).active ~ .indicator {
  transform: translateX(-60px) rotate(calc(360deg / var(--circle-count) * 4));
}

@media only screen and (max-width: 426px) {
  .profile-wrapper {
    width: 100vw;
    padding: 0;
  }

  .default-profile {
    padding: 11px 20px;
    width: 100%;
  }

  .home-options-popup {
    width: 140px;
    top: 55px;
    right: 0;
  }

  .hop-settings-label,
  .hop-widgets-label,
  .hop-logout-label {
    font-size: 14px;
  }

  .hop-settings-icon,
  .hop-widgets-icon,
  .hop-logout-icon {
    font-size: 12px;
  }

  .share-profile {
    width: 42px;
    height: 42px;
  }

  .share-icon {
    width: 23px;
    height: 23px;
  }

  .name {
    font-size: 20px;
  }

  .user-name {
    font-size: 16px;
  }

  .bio-desc {
    margin: 0;
  }

  .sites-view-popup {
    top: 200px;
    width: 300px;
  }

  .section4 {
    margin-bottom: 10px;
  }

  .section5 {
    margin-bottom: 5px;
    gap: 5px;
    width: 100%;
  }

  .tabs {
    margin-bottom: 5px;
  }

  .tab-section {
    gap: 10px;
    width: 100%;
  }

  .bar {
    width: 80px;
  }

  .add-pl {
    width: 30px;
    height: 30px;
  }

  .add-pl-icon {
    font-size: 16px;
  }

  .pl-icon-sec {
    width: 30px;
    height: 30px;
  }

  .pl-icon-img {
    width: 25px;
    height: 25px;
  }

  .pl-icon-sec-rio {
    width: 30px;
    height: 30px;
  }

  .pl-icon-img-rio {
    width: 21px;
    height: 21px;
  }

  .add-circles-tab {
    height: 150px;
  }

  .fab {
    bottom: 20px;
    right: 20px;
  }

  .share-own,
  .qr,
  .sites-add,
  .settings,
  .notifications,
  .user,
  .dots {
    width: 50px;
    height: 50px;
    background-size: 70%;
  }

  .share-own {
    width: 50px;
    height: 50px;
  }

  .share-own-icon {
    width: 30px;
    height: 30px;
  }

  .fab-qr-icon {
    width: 30px;
    height: 30px;
  }

  .fab-user-photo {
    width: 49px;
    height: 49px;
    object-fit: cover;
  }

  .dots .fab-icon {
    width: 60px;
    height: 60px;
  }

  .pl-icon-request {
    font-size: 22px;
    color: white;
  }

  .circle-canvas {
    gap: 20px;
  }

  .circle-type {
    width: 90px;
    height: 90px;
  }

  .drop-area {
    width: 252px;
    height: 55px;
  }

  .circle-container {
    width: 280px;
    height: 280px;
  }

  .circle-container li {
    left: 28px;
    transform-origin: 110px;
  }

  .circle-container li.active {
    transform: rotate(calc(360deg / var(--circle-count) * var(--i)))
      translateX(-38px);
  }

  .indicator {
    width: 80px;
    right: calc(28% + 1px);
  }

  .indicator::before {
    top: -50px;
    right: 70px;
    width: 100px;
    height: 100px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .profile-wrapper {
    padding: 0;
  }

  .default-profile {
    padding: 11px 28px;
    width: 428px;
  }

  .home-options-popup {
    width: 140px;
    top: 55px;
    right: 0;
  }

  .hop-settings-label,
  .hop-widgets-label,
  .hop-logout-label {
    font-size: 14px;
  }
  .hop-settings-icon,
  .hop-widgets-icon,
  .hop-logout-icon {
    font-size: 12px;
  }

  .share-profile {
    width: 42px;
    height: 42px;
  }

  .share-icon {
    width: 23px;
    height: 23px;
  }

  .name {
    font-size: 20px;
  }

  .user-name {
    font-size: 16px;
  }

  .sites-d {
    width: 20px;
    height: 20px;
  }

  .section4 {
    margin-bottom: 10px;
  }

  .section5 {
    margin-bottom: 5px;
    width: 100%;
  }

  .tabs {
    margin-bottom: 5px;
  }

  .tab-section {
    gap: 10px;
    width: 100%;
  }

  .bar {
    width: 80px;
  }

  .add-pl {
    width: 30px;
    height: 30px;
  }

  .add-pl-icon {
    font-size: 16px;
  }

  .pl-icon-sec {
    width: 30px;
    height: 30px;
  }

  .pl-icon-img {
    width: 25px;
    height: 25px;
  }

  .pl-icon-sec-rio {
    width: 30px;
    height: 30px;
  }

  .pl-icon-img-rio {
    width: 21px;
    height: 21px;
  }

  .add-circles-tab {
    height: 150px;
  }

  .fab {
    bottom: 20px;
    right: 20px;
  }

  .share-own,
  .qr,
  .sites-add,
  .settings,
  .notifications,
  .user,
  .dots {
    width: 50px;
    height: 50px;
    background-size: 70%;
  }

  .share-own {
    width: 50px;
    height: 50px;
  }

  .share-own-icon {
    width: 30px;
    height: 30px;
  }

  .fab-qr-icon {
    width: 30px;
    height: 30px;
  }

  .dots .fab-icon {
    width: 60px;
    height: 60px;
  }

  .fab-user-photo {
    width: 49px;
    height: 49px;
    object-fit: cover;
  }
}

.semi-donut-wrapper {
  width: 100px;
  height: 52px;
  overflow: hidden;
}

.semi-donut {
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  position: relative;
}

.progress-percentage {
  position: absolute;
  width: 100%;
  top: 40px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 10px;
  color: #bfbfbf;
}

.progress-percentage.green {
  color: #00b11b;
}

.progress-percentage.red {
  color: #b10000;
}

.progress-percentage.black {
  color: #000;
  font-weight: bold;
}

.ic-popup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 384px;
  padding: 5px 5px 20px 5px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  background: rgba(255, 255, 255, 1);
  text-align: center;
}

.ic-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.ic-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.ic-close-btn {
  background: none;
  border: none;
  float: right;
}

.ic-title {
  font-size: 24px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.custom-button{
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 25px;
  color: rgb(0, 0, 0);
  border: 2px solid #bbbbbb;
}

.ic-img-display {
  width: 100%;
  max-height: calc(100vh - 350px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  overflow: auto;
  object-fit: cover;
}

.ic-img-footer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-error {
  color: red;
  font-size: 15px;
  margin-top: 10px;
}

.ic-crop-image,
.ic-crop-save-image {
  color: white;
  font-size: 16px;
  padding: 5px 20px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: rgb(10, 72, 97);
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ic-crop-image:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.ic-crop-save-image:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.ic-canvas {
  margin-top: 20px;
}

@media screen and (max-width: 576px) {
  .ic-popup {
    width: 280px;
  }
}

.manage-circles-modal {
  width: 300px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  gap: 15px;
  z-index: 99;
}

.mcm-header {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mcm-close-btn {
  background: none;
  border: none;
  top: 5px;
  right: 0;
  position: absolute;
  cursor: pointer;
}

.mcm-header-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.mcm-title {
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  height: 100%;
}

.mcm-settings-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mcm-con-count {
  font-size: 10px;
  width: 25px;
  height: 25px;
  border:1px solid rgb(227, 227, 227);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mcm-body-search {
  width: 100%;
}

.mcm-search-input {
  width: 100%;
  height: 30px;
  border-radius: 20px;
  padding-left: 10px;
  font-size: 14px;
  background-color: #ebebeb;
  border: none;
  outline: none;
}

.mcm-body-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mcm-tab-name {
  font-size: 16px;
  font-weight: bold;
  color: #313131;
  cursor: pointer;
}

.mcm-tab-name.con-tab-active {
  color: rgb(0, 128, 255);
  font-weight: bold;
}

.mcm-body-connections {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow-y: auto;
  max-height: 200px;
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  font-size: 16px;
  color: #313131;
}

.mcm-body-connections::-webkit-scrollbar {
  width: 5px;
}

.mcm-body-connections::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.mcm-body-connections::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.mcm-body-connections::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.mcm-select-all {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 14px;
}

.mcm-connection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: fit-content;
}

.mcm-check-box {
  width: 15px;
  height: 15px;
  accent-color: rgb(75, 75, 75);
}

.mcm-con-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.mcm-con-name {
  flex: 1;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.mcm-footer-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.mcm-footer-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 5px 10px;
  color: white;
  background-color: #c0c0c0;
  border-radius: 20px;
}

.mcm-footer-btn.active{
  cursor: pointer;
  background-color: #313131;
}

.mcm-footer-btn.disable{
  pointer-events: none;
  cursor: none;
}
.gs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#0ddfff, #30c027);
  height: 100vh;
  padding: 10px 28px;
}

.gs-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 11px 28px;
  width: 428px;
  gap: 40px;
}

.gs-header {
  width: 100%;
  flex: none;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gs-logo {
  width: 200px;
  height: 200px;
}

.gs-title {
  text-align: center;
  color: #ffffff;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.gs-body {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gs-body-box {
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.gs-welcome {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

.gs-note {
  font-size: 22px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.gs-btn-box {
  align-items: center;
}

.gs-btn {
  width: 300px;
  height: 45px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 15px;
  -ms-box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.22);
  -o-box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.22);
  box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.22);
  background: rgba(255, 255, 255, 1);
  border: none;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #1ac99d;
}

.gs-btn:hover {
  color: rgba(255, 255, 255, 1);
  background: #118065;
}

.gs-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gs-footer p {
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  bottom: 0;
  width: 100%;
}

.heart-icon {
  color: red;
}

@media only screen and (max-width: 426px) {
  .gs-container {
    padding: 0;
    width: 100vw;
  }

  .gs-wrapper {
    padding: 11px 20px;
    width: 100%;
  }

  .gs-logo {
    width: 150px;
    height: 150px;
  }

  .gs-title {
    font-size: 24px;
    margin-top: 0;
  }

  .gs-body-box {
    gap: 10px;
  }

  .gs-welcome {
    font-size: 24px;
    margin-bottom: 0;
  }

  .gs-note {
    font-size: 20px;
    margin-bottom: 0;
  }

  .gs-btn {
    width: 200px;
    height: 40px;
    font-size: 18px;
  }

  .gs-footer p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 426px) {
  .gs-container {
    padding: 0;
    width: 100vw;
  }

  .gs-wrapper {
    padding: 11px 20px;
  }

  .gs-logo {
    width: 150px;
    height: 150px;
  }

  .gs-title {
    font-size: 24px;
    margin-top: 0;
  }

  .gs-body-box {
    gap: 10px;
  }

  .gs-welcome {
    font-size: 24px;
    margin-bottom: 0;
  }

  .gs-note {
    font-size: 20px;
    margin-bottom: 0;
  }

  .gs-btn {
    width: 200px;
    height: 40px;
    font-size: 18px;
  }

  .gs-footer p {
    font-size: 14px;
  }
}

.upgrade-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 340px;
  max-height: 500px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 20px;
  z-index: 9999;
}

.upgrade-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
}

.uh-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  font-size: 20px;
}

.uh-close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.upgrade-canvas {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  gap: 20px;
  max-height: 420px;
  margin-right: 5px;
  padding-left: 5px;
}

.upgrade-canvas::-webkit-scrollbar {
  width: 5px;
}

.upgrade-canvas::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.upgrade-canvas::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.upgrade-canvas::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.free-plan-canvas,
.silver-plan-canvas,
.gold-plan-canvas {
  width: 90%;
  padding: 10px;
  border-radius: 10px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.pc-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.278);
  padding-bottom: 10px;
}

.plan-name {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.plan-price {
  width: 100%;
  font-size: 28px;
  font-weight: bolder;
  text-align: center;
}

.plan-sub-desc {
  font-size: 16px;
  color: rgb(29, 31, 31);
  font-weight: bold;
}

.pc-middle-btn {
  width: 100%;
  padding: 5px;
  border-radius: 20px;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.156);
}

.pc-middle-btn:hover {
  background-color: rgba(0, 0, 0, 0.111);
}

.pc-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.plan-features {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.plan-features-icon {
  font-size: 16px;
  color: rgb(36, 39, 36);
}

.plan-features-desc {
  flex: 1;
  padding: 0;
  margin: 0;
  display: flex;
  text-align: left;
}

.pc-footer {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: rgb(37, 40, 41);
}

.free-plan-canvas {
  background: linear-gradient(rgba(13, 223, 255, 0.2), rgba(49, 192, 39, 0.2));
}

.silver-plan-canvas {
  background: linear-gradient(rgba(0, 0, 0, 0.06), rgba(19, 0, 106, 0.13));
}

.gold-plan-canvas {
  background: linear-gradient(rgba(255, 215, 17, 0.2), rgba(106, 0, 0, 0.2));
}

.click-plan-compare {
  cursor: pointer;
  border-bottom: 1px solid rgb(0, 0, 61);
  color: rgb(0, 0, 61);
}

@media only screen and (max-width: 768px) {
  .upgrade-modal {
    height: 470px;
    width: 300px;
    max-height: 470px;
  }
}

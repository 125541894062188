.demo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#0ddfff, #30c027);
  height: 100vh;
  padding: 10px 28px;
  overflow: auto;
}

.demo-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 11px 28px;
  width: 428px;
  gap: 10px;
}

.demo-header {
  width: 100%;
  flex: none;
  height: fit-content;
}

.demo-header .progress-header {
  width: 100%;
  flex: none;
  height: fit-content;
}

.demo-header .ul-box2,
.demo-header .ul-box3 {
  background: rgba(33, 205, 130, 1);
}

.demo-body {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.db-wrapper {
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.db-topic {
  width: 100%;
  font-size: 26px;
  line-height: 1.3;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.db-sub-topic {
  width: 100%;
  font-size: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  color: #f3f3f3;
}

.add-photo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-demo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.demo-location,
.demo-text {
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 10px;
  padding-left: 20px;
  height: 40px;
}

.location-sec {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location-sec .label {
  font-size: 14px;
  color: rgb(117, 117, 117);
  width: 100%;
  text-align: left;
}

.demo-location {
  width: 100%;
  padding: 0;
  margin: 0;
}

.demo-location .css-13cymwt-control {
  border-radius: 10px;
  width: 100% !important;
  border: none;
  height: 40px;
  outline: none;
}

.demo-text::placeholder {
  color: #cacaca;
}

.designation-sec,
.workplace-sec {
  width: 100%;
}

.designation-sec .label,
.workplace-sec .label {
  font-size: 14px;
  color: rgb(117, 117, 117);
  width: 100%;
  text-align: left;
}

.db-button {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.db-button button {
  border: none;
  background: none;
  font-size: 20px;
  font-weight: bold;
  color: #e7f0ee;
  text-align: center;
}

.db-button button:hover {
  font-size: 21px;
}

.db-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.db-footer p {
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  bottom: 0;
  width: 100%;
  margin: 0;
}

.db-footer .heart-icon {
  color: red;
}

.demo-error-message {
  color: white;
  width: 100%;
  font-size: 14px;
  margin-top: 5px;
}

.demo-error-message p {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 426px) {
  .demo-container {
    width: 100vw;
    padding: 0;
  }

  .demo-wrapper {
    width: 100%;
  }

  .db-wrapper {
    gap: 20px;
  }

  .db-topic {
    font-size: 20px;
  }

  .db-sub-topic {
    font-size: 18px;
  }

  .add-demo {
    gap: 10px;
  }

  .demo-location,
  .demo-text {
    font-size: 14px;
    padding-left: 10px;
    height: 35px;
  }

  .location-sec .label {
    font-size: 12px;
  }

  .demo-location .css-13cymwt-control {
    height: 35px;
  }

  .designation-sec .label,
  .workplace-sec .label {
    font-size: 12px;
  }

  .db-button button {
    font-size: 18px;
  }

  .db-button button:hover {
    font-size: 19px;
  }

  .db-footer p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 426px) {
  .demo-container {
    padding: 0;
  }

  .demo-wrapper {
    padding: 11px 28px;
    width: 428px;
  }

  .db-wrapper {
    gap: 20px;
  }

  .db-topic {
    font-size: 20px;
  }

  .db-sub-topic {
    font-size: 18px;
  }

  .add-demo {
    gap: 20px;
  }

  .demo-location,
  .demo-text {
    font-size: 14px;
    padding-left: 10px;
    height: 35px;
  }

  .db-button button {
    font-size: 18px;
  }

  .db-button button:hover {
    font-size: 19px;
  }

  .db-footer p {
    font-size: 14px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-canvas {
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  bottom: 0;
  width: 100%;
  margin: 0;
}

.heart-icon {
  color: red;
}

@media only screen and (max-width: 426px) {
  .footer-canvas {
    font-size: 14px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .footer-canvas {
    font-size: 14px;
  }
}

.report-profile-popup {
  width: 320px;
  max-height: 600px;
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 20px;
  z-index: 999;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0 0 69px 0 rgba(0, 0, 0, 0.21);
  text-align: center;
  padding:5px 20px 20px 20px;
}

.rpp-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.rpp-close {
  top: 0;
  right: 0;
  position: absolute;
}

.rpp-title {
  font-size: 24px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.rpp-body {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.report-profile-type {
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.report-profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.report-title {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
}

.report-type-icon {
  font-size: 20px;
  cursor: pointer;
}

.quick-report-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f0f0f0;
  width: 100%;
  padding: 20px;
  gap: 10px;
  border-radius: 5px;
  max-height: 350px;
  overflow-y: auto;
}

.quick-report-body::-webkit-scrollbar {
  width: 0;
}

.quick-report-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.quick-report-body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.quick-report-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.quick-report-sub-title {
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.choose-inappropriate-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  text-align: left;
  gap: 10px;
  background-color: white;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}

.ic-radio {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.ic-radio-btn {
  font-size: 12px;
}

.rp-footer {
  width: 96px;
  height: 35px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(58, 58, 58, 1);
  font-family: Inria Sans, serif;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.rp-footer:hover {
  background: rgb(0, 0, 0);
}

.report-other-body {
  font-size: 12px;
  border-radius: 10px;
  padding: 10px;
  width:200px;
  border: 1px solid rgb(186, 186, 186);
  outline: none;
}

@media only screen and (max-width: 426px) {
  .report-profile-popup {
    width: 280px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 10px;
  }

  .rpp-body {
    padding: 0 14px;
  }

  .report-other-body {
    width:150px;
  }
}

@media screen and (max-width: 768px) and (min-width: 427px) {
  .report-profile-popup {
    width: 320px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 10px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 769px) {
  .report-profile-popup {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

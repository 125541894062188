.info-sec {
  position: relative;
}

.info-icon {
  border-radius: 50%;
  color: rgb(108, 108, 108);
  background-color: #ededed;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  cursor: pointer;
}

.info-desc {
  position: absolute;
  width: 200px;
  font-size: 10px;
  background-color: #dddddd;
  border-radius: 5px;
  right: 20px;
  top: -5px;
  padding: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}

.info-icon:hover + .info-desc {
  opacity: 1;
  visibility: visible;
}
.add-circle-popup {
  width: 320px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background: rgba(255, 255, 255);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  gap: 20px;
  position: relative;
}

.acp-header,
.ccp-header {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
  padding: 0 20px;
}

.acp-title,
.ccp-title {
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  height: 100%;
  width: 100%;
}

.acp-close,
.ccp-close-btn {
  background: none;
  border: none;
  top: 5px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}

.acp-body-canvas {
  width: auto;
  max-height: 300px;
  overflow: auto;
  margin: 0 10px;
}

.acp-body-canvas::-webkit-scrollbar {
  width: 5px;
}

.acp-body-canvas::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.acp-body-canvas::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.acp-body-canvas::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.acp-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin: 0 10px;
}

.ccp-new-circle-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.acp-footer,
.ccp-footer {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.acp-select-color-btn,
.ccp-add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  background-color: rgb(59, 59, 59);
  padding: 5px 15px;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}

.acp-select-color-btn:hover,
.ccp-add-btn:hover {
  background-color: rgb(0, 0, 0);
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.circle-color-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 270px;
  padding: 0 10px;
  border-radius: 25px;
  background: rgba(255, 255, 255);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ccp-header {
  height: 50px;
}

.ccp-title {
  font-size: 20px;
}

.ccp-body,
.ccp-body-modify {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding: 0 10px;
}

.ccp-body-modify {
  padding: 0 20px;
  gap: 15px;
}

.ccp-body-title,
.ccp-body-title-modify,
.ccp-new-name-label {
  font-size: 16px;
  color: #646464;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
}

.ccp-body-title-modify {
  width: 100%;
}

.ccp-color-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 20px;
  background-color: #f0f0f0;
  padding: 20px;
}

.ccp-sticker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 10px;
  background-color: #f0f0f0;
  padding: 10px 5px;
  height: fit-content;
}

.ccp-sticker-box {
  width: 100%;
  overflow-y: auto;
  border-radius: 5px;
}

.ccp-sticker-box::-webkit-scrollbar {
  width: 5px;
}

.ccp-sticker-box::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.ccp-sticker-box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.ccp-sticker-box::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
  border-radius: 5px;
}

.ccp-color-box {
  width: 55px;
  height: 55px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.ccp-body-search {
  padding: 10px;
}

.ccp-search-input {
  width: 100%;
  height: 30px;
  border-radius: 20px;
  padding-left: 10px;
  font-size: 14px;
  background-color: #ebebeb;
  border: none;
  outline: none;
}

.ccp-color-box:nth-child(1) {
  background: linear-gradient(#faff00, #dc7f19);
  border: 2px solid #dc7f19;
}

.ccp-color-box:nth-child(2) {
  background: linear-gradient(#01e6ff, #0004ff);
  border: 2px solid #0004ff;
}

.ccp-color-box:nth-child(3) {
  background: linear-gradient(#e4c0e1, #5e3c83);
  border: 2px solid #5e3c83;
}

.ccp-color-box:nth-child(4) {
  background: linear-gradient(#9eff00, #0e883b);
  border: 2px solid #0e883b;
}

.ccp-color-box:nth-child(5) {
  background: linear-gradient(#ffaccf, #af1517);
  border: 2px solid #af1517;
}

.ccp-footer {
  margin-bottom: 10px;
}

.ccp-color-select {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.608);
  color: rgb(76, 76, 76);
  font-size: 18px;
}

.ccp-new-name-input {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #b6b6b6;
  border-radius: 5px;
  outline: none;
  width: 100%;
}

.ccp-new-name-input::placeholder {
  color: #b6b6b6;
  font-size: 14px;
}

.circle-upgrade-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.site-alert-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
}

.site-upgrade-alert {
  font-weight: bold;
  font-size: 14px;
}

.input-circle-canvas {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
}

.required-circle-name {
  color: red;
}

.ccp-icons-container {
  display: flex;
}

.ccp-icon-set {
  flex-grow: 1;
}

.icons-based-on-type {
  flex-grow: 5;
  background-color: white;
  margin-right: 5px;
  border-radius: 10px;
  padding: 5px;
}

.ccp-icons-row {
  display: flex;
  gap: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ccp-icon-type {
  font-size: 14px;
  margin: 5px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.ccp-icon-type:hover {
  background-color: rgb(207, 206, 206);
  cursor: pointer;
}

.active-icons {
  background-color: rgb(207, 206, 206);
}

.ccp-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  cursor: pointer;
}

.ccp-icon {
  font-size: 20px;
}

.ccp-icon.active {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 36px -7px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0 0 36px -7px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 0 36px -7px rgba(0, 0, 0, 1);
}

.ccp-icon-name {
  font-size: 8px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

@media only screen and (max-width: 426px) {
  .add-circle-popup {
    width: 290px;
  }
}

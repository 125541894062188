.demographic {
  gap: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
}

.demo-icon {
  width: 16px;
  height: 16px;
}

.demo-title {
  font-size: 10px;
  color: rgba(255, 255, 255, 90%);
}

.location-popup {
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 320px;
}

.location-popup-header {
  width: 100%;
}

.location-box {
  height: 60px;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
  width: 100%;
}

.location-title {
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  width: 100%;
  height: 100%;
}

.location-body {
  width: 90%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.location-country,
.location-city {
  width: 100%;
}

.location-city .css-13cymwt-control,
.location-country .css-13cymwt-control {
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: left;
}

.select-country,
.select-city {
  padding: 0;
  margin: 0;
}

.loc-pb-error-message {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  color: red;
  font-size: 14px;
  padding-left: 3px;
}

.location-close {
  top: 5px;
  right: 15px;
  position: absolute;
}

.location-close-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.location-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 20px;
}

.location-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 35px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(58, 58, 58, 1);
  font-size: 20px;
  border: none;
  text-align: center;
  color: #ffffff;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.location-add:hover {
  background: rgb(36, 35, 35);
}

.location-update-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.location-update {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  padding: 5px 10px;
  border-radius: 25px;
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.location-update:hover {
  background: rgb(27, 27, 27);
}

.location-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 18px;
  border: 2px solid rgb(195, 195, 195);
  background-color: transparent;
  text-align: center;
}

.location-delete:hover {
  background: rgb(220, 220, 220);
}

@media screen and (max-width: 576px) {
  .location-popup {
    width: 300px;
    text-align: center;
    gap: 10px;
  }

  .location-box {
    height: 60px;
  }

  .location-title {
    font-size: 20px;
    text-align: center;
  }

  .select-country,
  .select-city {
    font-size: 13px;
  }

  .location-add {
    width: 90px;
    height: 30px;
    font-size: 18px;
  }

  .location-delete,
  .location-update {
    font-size: 16px;
  }

  .loc-pb-error-message {
    font-size: 12px;
    padding-left: 3px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 577px) {
  .location-popup {
    width: 300px;
    text-align: center;
  }

  .select-country,
  .select-city {
    font-size: 14px;
  }

  .location-delete,
  .location-update {
    font-size: 16px;
  }
}

.direct-notice-container {
  width: 100vw;
  height: 100vh;
  background-color: rgb(98, 103, 137);
  display: flex;
  align-items: center;
  justify-content: center;
}

.direct-notice {
  position: fixed;
  background-color: white;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border-radius: 5px;
}

.d-notice-close {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.d-notice-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-notice-image img {
  width: 200px;
}

.d-notice-title {
  font-weight: bold;
  font-size: 20px;
}

.d-notice-desc {
  font-size: 12px;
  text-align: justify;
}

.d-notice-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.notice-continue,
.notice-stay {
  width: 100px;
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.notice-continue {
  border: 2px solid rgb(156, 156, 156);
  transition: 0.5s;
}

.notice-continue:hover {
  background-color: rgb(215, 215, 215);
}

.notice-stay {
  background-color: rgb(86, 86, 86);
  color: white;
  transition: 0.5s;
}

.notice-stay:hover {
  background-color: rgb(26, 26, 26);
}

@media only screen and (max-width: 426px) {
  .direct-notice-container {
    background-color: rgb(255, 255, 255);
  }

  .direct-notice {
    width: 300px;
    gap: 30px;
  }

  .d-notice-close {
    display: none;
  }

  .d-notice-desc {
    font-size: 14px;
  }

  .d-notice-btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  .notice-continue,
  .notice-stay {
    height: 35px;
    font-size: 14px;
    width: 90px;
  }

  .notice-continue {
    border: 2px solid rgb(156, 156, 156);
    transition: 0.5s;
  }

  .notice-continue:hover {
    background-color: rgb(215, 215, 215);
  }

  .notice-stay {
    background-color: rgb(86, 86, 86);
    color: white;
    transition: 0.5s;
  }

  .notice-stay:hover {
    background-color: rgb(26, 26, 26);
  }
}

.progress-header {
  width: 100%;
  flex: none;
  height: fit-content;
}

.ul-progress-box-ulb-t1 {
  background: #bc151b;
}

.ul-progress-box-ulb-t2 {
  background: #30c027;
}

.ul-progress-box-ulb-t3 {
  background: #185cc8;
}

.ul-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ul-progress-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.ul-box1 {
  width: 60px;
  height: 6px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.ulb-t1 .ul-box1 {
  background-color: #cb5067;
}

.ulb-t2 .ul-box1 {
  background: rgba(33, 205, 130, 1);
}

.ulb-t3 .ul-box1 {
  background-color: #547dca;
}

.ul-box2,
.ul-box3,
.ul-box4 {
  width: 60px;
  height: 6px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(245.21, 245.21, 245.21);
}

.ul-skip {
  flex: none;
  width: fit-content;
  font-size: 18px;
  color: #8b8b8b;
  background: none;
  border: none;
  outline: none;
}

.ul-progress-num {
  font-size: 16px;
  text-align: center;
  color: #8b8b8b;
  align-items: center;
}

.skip-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 350px;
  padding: 25px;
  z-index: 100;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
}

.skip-msg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}

.skip-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 15px;
}

.skip-cancel-btn,
.skip-logout-btn {
  width: 80px;
  padding: 5px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skip-cancel-btn {
  border: 2px solid rgb(195, 195, 195);
}

.skip-logout-btn {
  background: rgb(66, 66, 66);
  border: 2px solid rgb(66, 66, 66);
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 426px) {
  .ul-box1,
  .ul-box2,
  .ul-box3,
  .ul-box4 {
    width: 45px;
    height: 5px;
  }

  .ul-skip {
    font-size: 16px;
  }

  .ul-progress-num {
    font-size: 14px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 426px) {
  .ul-box1,
  .ul-box2,
  .ul-box3,
  .ul-box4 {
    width: 70px;
    height: 8px;
  }

  .ul-skip {
    font-size: 16px;
  }

  .ul-progress-num {
    font-size: 14px;
  }
}

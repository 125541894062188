.confirmation-msg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  color: black;
}

.cm-container {
  background: white;
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.confirmation-msg-title {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.cm-close {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.cm-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.cm-warning-icon,
.cm-success-icon {
  font-size: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cm-warning-icon {
  color: rgb(180, 3, 3);
}

.cm-success-icon {
  color: greenyellow;
}

.cm-desc {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cm-desc-error {
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cm-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cm-ok {
  padding: 3px 10px;
  width: 60px;
  border-radius: 20px;
  cursor: pointer;
  border: 2px solid rgb(78, 78, 78);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.cm-ok:hover {
  background-color: rgb(178, 178, 178);
}


@media only screen and (max-width:426px){
  .cm-container {
    width: 90%;
  }
}
.theme-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 10px 28px;
  overflow: auto;
}

.theme-container .progress-header {
  width: 100%;
  flex: none;
  height: fit-content;
}

.theme-container .progress-header .ul-progress .ul-progress-box.ulb-t1 .ul-box2,
.theme-container .progress-header .ul-progress .ul-progress-box.ulb-t1 .ul-box3,
.theme-container
  .progress-header
  .ul-progress
  .ul-progress-box.ulb-t1
  .ul-box4 {
  background-color: #cb5067;
}

.theme-container .progress-header .ul-progress .ul-progress-box.ulb-t2 .ul-box2,
.theme-container .progress-header .ul-progress .ul-progress-box.ulb-t2 .ul-box3,
.theme-container
  .progress-header
  .ul-progress
  .ul-progress-box.ulb-t2
  .ul-box4 {
  background: rgba(33, 205, 130);
}

.theme-container .progress-header .ul-progress .ul-progress-box.ulb-t3 .ul-box2,
.theme-container .progress-header .ul-progress .ul-progress-box.ulb-t3 .ul-box3,
.theme-container
  .progress-header
  .ul-progress
  .ul-progress-box.ulb-t3
  .ul-box4 {
  background-color: #547dca;
}

.theme-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 11px 28px;
  width: 428px;
  gap: 10px;
}

.theme-header {
  width: 100%;
  flex: none;
  height: fit-content;
}

.theme-body {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.theme-topic {
  width: 100%;
  font-size: 26px;
  text-align: left;
  color: #ffffff;
  font-weight: bold;
}

.theme-sub-topic {
  width: 100%;
  font-size: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  color: #f3f3f3;
}

.theme-select {
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 20px;
}

.themes {
  position: relative;
  perspective: 1500px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 260px;
}

.themes label {
  position: absolute;
  width: fit-content;
  transition: transform 0.55s ease;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.card {
  height: 100%;
}

.card img {
  height: 170px;
  width: 100px;
  object-fit: cover;
}

.theme-note {
  width: 100%;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #ffffff;
  margin: 0;
  padding: 5px;
  border-radius: 10px;
}

#t1:checked ~ .themes #theme-red,
#t2:checked ~ .themes #theme-green,
#t3:checked ~ .themes #theme-blue {
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.41);
  transform: translate3d(0, 0, 300px);
}

#t1:checked ~ .themes #theme-green,
#t2:checked ~ .themes #theme-blue,
#t3:checked ~ .themes #theme-red {
  transform: translate3d(-100px, 0, -100px);
}

#t1:checked ~ .themes #theme-blue,
#t2:checked ~ .themes #theme-red,
#t3:checked ~ .themes #theme-green {
  transform: translate3d(100px, 0, -100px);
}

.theme-button {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-prev {
  border: none;
  background: none;
  font-size: 20px;
  font-weight: bold;
  color: #e7f0ee;
  text-align: center;
}

.btn-prev:hover {
  font-size: 21px;
}

.btn-save {
  font-size: 20px;
  font-weight: bold;
  color: #e7f0ee;
  text-align: center;
  width: 100px;
  height: 40px;
  background: none;
  border: 2px solid white;
  border-radius: 20px;
  transition: 0.3s;
}

.btn-save.btn-t1:hover {
  color: #cb5067;
}

.btn-save.btn-t3:hover {
  color: #547dca;
}

.btn-save:hover {
  color: #1ac99d;
  background-color: white;
}

@media only screen and (max-width: 426px) {
  .theme-container {
    width: 100vw;
    padding: 0;
  }

  .theme-wrapper {
    width: 100%;
  }

  .theme-body {
    gap: 20px;
  }

  .theme-topic {
    font-size: 20px;
  }

  .theme-sub-topic {
    font-size: 18px;
  }

  .theme-select {
    gap: 10px;
  }

  .themes {
    height: 240px;
  }

  .card img {
    height: 150px;
    width: 90px;
    object-fit: cover;
  }

  .theme-note {
    font-size: 14px;
  }

  #t1:checked ~ .themes #theme-red,
  #t2:checked ~ .themes #theme-green,
  #t3:checked ~ .themes #theme-blue {
    transform: translate3d(0, 0, 280px);
  }

  #t1:checked ~ .themes #theme-green,
  #t2:checked ~ .themes #theme-blue,
  #t3:checked ~ .themes #theme-red {
    transform: translate3d(-90px, 0, -100px);
  }

  #t1:checked ~ .themes #theme-blue,
  #t2:checked ~ .themes #theme-red,
  #t3:checked ~ .themes #theme-green {
    transform: translate3d(90px, 0, -100px);
  }

  .btn-prev {
    font-size: 18px;
  }

  .btn-prev:hover {
    font-size: 19px;
  }

  .btn-save {
    font-size: 18px;
    height: 35px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 426px) {
  .theme-container {
    padding: 0;
  }

  .theme-wrapper {
    padding: 11px 28px;
    width: 428px;
  }

  .theme-body {
    gap: 20px;
  }

  .theme-topic {
    font-size: 20px;
  }

  .theme-sub-topic {
    font-size: 18px;
  }

  .theme-select {
    gap: 20px;
  }

  .themes {
    height: 240px;
  }

  .theme-note {
    font-size: 14px;
  }

  .btn-prev {
    font-size: 18px;
  }

  .btn-prev:hover {
    font-size: 19px;
  }

  .btn-save {
    font-size: 18px;
    height: 35px;
  }
}

.sticker-popup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 384px;
  max-height: calc(100vh - 140px);
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 0 5px 40px 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.sticker-menu-bar {
  display: none;
}

.sticker-to-home {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-photo {
  width: 37px;
  height: 37px;
  object-fit: cover;
  color: rgb(128, 128, 128);
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
}

.sticker-to-home-icon {
  width: 37px;
  height: 37px;
  object-fit: cover;
  color: rgb(128, 128, 128);
  cursor: pointer;
  overflow: hidden;
}

.sticker-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgba(109, 109, 109, 0.31);
  position: relative;
}

.sticker-title {
  font-size: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  height: 50px;
}

.sticker-close {
  position: absolute;
  right: 12px;
  top: 5px;
}

.sticker-close-btn {
  background: none;
  border: none;
  float: right;
}

.sticker-container {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.sticker-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.sticker-library,
.my-collection {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-bottom: none;
  padding: 10px 0;
  border-radius: 20px 20px 0 0;
  width: 130px;
  cursor: pointer;
  font-weight: bold;
}

.sticker-library.active-sl {
  border-bottom: 2px solid rgb(196, 196, 196);
}

.my-collection.active-mc {
  border-bottom: 2px solid rgb(196, 196, 196);
}

.sticker-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: fit-content;
  width: 100%;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 1s ease, opacity 1s ease;
}

.sticker-body.open {
  max-height: 350px;
  opacity: 1;
}

.sticker-body::-webkit-scrollbar {
  width: 5px;
}

.sticker-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.sticker-body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.sticker-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.401);
}

.sticker-body-canvas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: fit-content;
  width: 100%;
}

.sticker-collection-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 30px;
  padding: 0 10px;
}

.sticker-category {
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  transition: background-color 0.3s ease, color 1s ease;
}

.sticker-category.active-category {
  background-color: #4b4b4b;
  color: white;
  font-weight: bold;
  border-bottom: 0;
  border-radius: 5px;
}

.sticker-category-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-view {
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
}

.stickers,
.sticker-card-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.sticker-card-canvas {
  gap: 30px;
  overflow: auto;
  transition: 1s ease-in-out;
}

.sticker-card-canvas::-webkit-scrollbar {
  width: 5px;
}

.sticker-image {
  width: 60px;
  object-fit: cover;
}

.sticker-card,
.sticker-card-type2 {
  width: 130px;
  height: 150px;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.sticker-card-image-t1 {
  width: 80px;
  height: 80px;
}

.sticker-card-image-t2 {
  width: 90px;
}

.sticker-card-view {
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid #aaaaaa;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.sticker-card-view:hover {
  background-color: #f0f0f0;
}

.view-sticker-canvas {
  border-radius: 10px;
  width: 420px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: white;
  box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 0 400px -2px rgba(0, 0, 0, 0.58);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.view-sticker-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.view-sticker-close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.view-sticker-title {
  font-size: 16px;
  font-weight: bold;
}

.view-sticker-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 0 10px;
}

.preview-sticker {
  width: 150px;
  height: 150px;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
}

.customize-sticker {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.customize-category {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  font-size: 12px;
}

.view-sticker-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  width: 100%;
}

.sticker-print,
.sticker-download {
  width: 100px;
  padding: 5px;
  border: 1px solid #aaaaaa;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.add-to-collection {
  position: absolute;
  right: 20px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

@media screen and (max-width: 426px) {
  .sticker-popup {
    gap: 10px;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
    padding: 10px 10px 20px 10px;
  }

  .sticker-menu-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(59, 59, 59);
    padding: 5px 10px;
    border-radius: 25px;
  }

  .sticker-header {
    display: none;
  }

  .smb-title {
    font-size: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #d5d5d5;
  }

  .sticker-container {
    height: 100vh;
    max-height: 100vh;
    z-index: 10;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .sticker-tabs {
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sticker-body {
    max-height: calc(100vh - 150px);
    flex: 1;
    overflow-y: auto;
  }

  .sticker-body.open {
    max-height: 400px;
    opacity: 1;
  }

  .sticker-collection-tabs {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) and (min-width: 427px) {
  .sticker-popup {
    max-width: 370px;
    gap: 10px;
    padding-bottom: 50px;
  }

  .smb-title {
    font-size: 20px;
    height: 40px;
  }

  .sticker-container {
    gap: 20px;
  }

  .sticker-tabs {
    gap: 20px;
  }

  .sticker-body {
    max-height: 300px;
    flex: 1;
    overflow-y: auto;
  }

  .sticker-collection-tabs {
    width: 100%;
  }

  .sticker-category{
    padding: 8px;
  }
}

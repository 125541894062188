.donut-wrapper {
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.donut {
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  position: relative;
  font-weight: bold;
}

.donut-progress-percentage {
  position: absolute;
  width: 100%;
  top: 45px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 10px;
  color: #bfbfbf;
}
